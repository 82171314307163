import {Log} from "oidc-client-ts";
import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css';
import App from './App';
import {OidcAuthProvider} from "./components/authprovider/OidcAuthProvider";
import {PortalNavigationRouter} from "./components/PortalNavigationRouter";
import {ServiceWorkerProvider} from './ServiceWorkerProvider';
import {settings} from "./settings";

const targetElement = document.getElementById('root')!;
Log.setLogger(console);
Log.setLevel(Log.INFO);
ReactDOM.createRoot(targetElement).render(
	<OidcAuthProvider authority={settings.authorityUrl} client_id={settings.clientId} loadUserInfo={true} redirect_uri={window.location.href} >
			<ServiceWorkerProvider>
				<PortalNavigationRouter>
					<App/>
				</PortalNavigationRouter>
			</ServiceWorkerProvider>
	</OidcAuthProvider>,

);
