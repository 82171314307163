import {BvoNotifierMessage, NotifyMessage} from "@intentus/bvo-theme-react";
import {useAtom} from "jotai";
import React, {useCallback} from "react";
import {notifyMessageAtom} from "../contexts/atoms";

export const NotifierMessageContainer = ():JSX.Element => {
	const [ msg, setMessage ] = useAtom(notifyMessageAtom);
	const closeMessage = () => {
		console.debug("close notify", msg);
		setMessage(null);
	}
	return <> {msg && <BvoNotifierMessage message={msg}  open={true} onclose={closeMessage}/>} </>
}


export const useNotifyError = () => {
	const [, setNotifyMessage] = useAtom(notifyMessageAtom);
	return useCallback((error: Error) => {
		if (error.name !== 'AbortError') {
			console.log(error);
			const message: NotifyMessage = {message: error.message, severity: "error", autohide: 10000};
			setNotifyMessage(message);
		}
		return Promise.reject(error.message);
	}, [setNotifyMessage]);
}

export const useNotifySuccess = () => {
	const [, setNotifyMessage] = useAtom(notifyMessageAtom);
	return useCallback((info: string) => {
		const message: NotifyMessage = {message: info, severity: "success", autohide: 3000};
		setNotifyMessage(message);
		return Promise.resolve();
	}, [setNotifyMessage]);
}
