import {Notifications} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
	Avatar,
	Badge,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Switch,
	Theme,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import React, {ReactElement, useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {useServiceWorker} from "../../ServiceWorkerProvider";
import {useAuth} from "../authprovider/useAuth";
import {NotificationEntry, NotificationsAPI, useNotificationsAPI} from "../hooks/useNotificationsAPI";
import {PortalMenuAPI, usePortalMenu} from "../hooks/usePortalMenu";


const PortalBenachrichtigungen = styled((Typography))(({theme}:{theme: Theme}) => ({

	color: theme.palette.text.primary,
	marginTop: theme.spacing(3),
	display: 'inline-block',
	width:'100%',
	'table': {
		width: '100%',
		minWidth: '400px'
	},
	'table th': {
		textAlign: 'left',
		padding: '16px',
		color: theme.palette.primary.main,
		fontWeight: '500 !important'
	},
	'table td': {
		textAlign: 'left',
		padding: '16px',
		color: 'inherit'
	},
	'table tbody tr:nth-of-type(2n)': {
		backgroundColor: 'rgb(248, 248, 248)'
	},
	'table tbody tr:nth-of-type(2n+1)': {
		backgroundColor: theme.palette.background.paper
	},
	'table tbody tr:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
	}

}));

export const ToolbarNotifications = ({menuAPI}: { menuAPI: PortalMenuAPI }): ReactElement => {
	const notifyAPI = useNotificationsAPI(menuAPI.menu.notifications);
	const [open, setOpen] = useState(false);

	return notifyAPI.entries !== undefined ? (
			<>
				<IconButton onClick={() => setOpen(true)} color={'inherit'}>
					<Badge badgeContent={notifyAPI.unreadEntries} color="secondary">
						<Notifications fontSize={"medium"} color={'inherit'}/>
					</Badge>
				</IconButton>
				<NotificationDialog notifyAPI={notifyAPI} open={open} setOpen={setOpen}/>
			</>
		)
		: <></>
}

const NotificationDialog = ({notifyAPI, open, setOpen}: {
	notifyAPI: NotificationsAPI, open: boolean, setOpen: (show: boolean) => void
}): ReactElement => {

	const auth = useAuth();
	const sw = useServiceWorker()
	const portalMenu = usePortalMenu();
	const handleClose = () => setOpen(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const markAllAsRead = () => notifyAPI.markAllAsRead(notifyAPI.entries.filter(e => !e.readByUser).map(e => e.id));
	const markAsRead = (entry: NotificationEntry) => {
		if (!entry.readByUser) {
			return notifyAPI.markAsRead(entry.id);
		}
		return Promise.resolve();
	}

	const [isPushAvailable, setIsPushAvailable] = useState<boolean>();
	const [isPushSubscribed, setIsPushSubscribed] = useState<boolean>();

	useEffect(() => {
		let subscribed = true;
		sw.isPushAvailable().then(res => subscribed && setIsPushAvailable(res)).catch(err => console.log(err));
		return () => {
			subscribed = false;
		}
	}, [sw])

	useEffect(() => {
		let subscribed = true;
		sw.isSubscribedToPush().then(res => subscribed && setIsPushSubscribed(res)).catch(err => console.log(err));
		return () => {
			subscribed = false;
		}
	}, [sw])

	const subscribeOrUnsubscribe = () => {
		if (isPushSubscribed) {
			sw.pushUnsubscribe(portalMenu.menu.notifications!.pushBackendUrl!, auth.self!.access_token)
				.then(() => setIsPushSubscribed(false))
				.catch(console.error);
		}
		else {
			sw.pushSubscribe(
				portalMenu.menu.notifications!.pushBackendUrl!,
				portalMenu.menu.notifications!.pushPubKey!,
				auth.self!.access_token
			)
				.then(() => setIsPushSubscribed(true))
				.catch(console.error);
		}
	}

	return <Dialog open={open} maxWidth={"md"} fullWidth={true} fullScreen={fullScreen} onClose={handleClose}
						scroll={"paper"}>
		<DialogTitle component={"span"}>
			<Typography variant={"h4"}>Benachrichtigungen</Typography>
			{isPushAvailable === true && portalMenu.menu.notifications?.pushBackendUrl !== undefined &&
					<Typography variant={"body2"}>
						Push-Benachrichtigungen im Browser&nbsp;
						<Link component={RouterLink} to={"/"} onClick={subscribeOrUnsubscribe}>
							{isPushSubscribed ? 'deaktivieren' : 'aktivieren'}
						</Link>
					</Typography>
			}
		</DialogTitle>
		<DialogContent>
			<IconButton style={{position: "absolute", top: "0", right: "0"}} onClick={handleClose}>
				<CloseIcon/>
			</IconButton>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<FormControlLabel
					control={<Switch
						checked={notifyAPI.filterUnreadOnly}
						onChange={() => notifyAPI.setFilterUnreadOnly(!notifyAPI.filterUnreadOnly)}
					/>}
					label="Nur ungelesene" labelPlacement="end"/>
				{notifyAPI.filterUnreadOnly && notifyAPI.entries.length > 0 &&
						<Button onClick={markAllAsRead}>Alle als gelesen markieren</Button>
				}
			</Stack>
			<Box sx={{display: {xs: 'none', sm: 'block', height: '500px', overflowY: 'auto'}}}>
				<NotificationList notifyAPI={notifyAPI} markAsRead={markAsRead}/>
			</Box>
			<Box sx={{display: {xs: 'block', sm: 'none'}}}>
				<NotificationList notifyAPI={notifyAPI} markAsRead={markAsRead}/>
			</Box>
		</DialogContent>
	</Dialog>
}

export const NotificationListItem = ({
	entry,
	markAsRead,
}: { entry: NotificationEntry, markAsRead: (entry: NotificationEntry) => Promise<void>, showDescrition?: boolean }): ReactElement => {

	return <>
		<ListItemAvatar>
			<Avatar sx={{bgcolor: grey[500]}}>{(entry.sender.displayName ?? entry.sender.username).at(0)}</Avatar>
		</ListItemAvatar>
		<ListItemText
			primary={
				<Grid container>
					<Grid item xs={11}><Typography
					variant={"body1"} component={'span'} color={'primary'}
					sx={{width: 'fit-content', cursor: 'pointer', fontWeight: !entry.readByUser ? 'bold' : 'normal'}}>
					{entry.title}
					</Typography>
					</Grid>
					<Grid item xs={1}>{!entry.readByUser &&
						<Tooltip title={"Nachricht als gelesen markieren"}>
							<IconButton color="primary" onClick={() => markAsRead(entry)} sx={{float: 'right'}}>
								<FiberManualRecordIcon fontSize={'small'}/>
							</IconButton>
						</Tooltip>}
					</Grid>
				</Grid>
			}
			secondaryTypographyProps={{component: 'span'}}
			secondary={<Grid container>
				<Grid item xs={11}>
					<Typography variant={"body2"} color={'textPrimary'}
														 sx={{width: 'calc(100% - 30px)', fontWeight: !entry.readByUser ? 'bold' : 'normal'}}>
					{'gesendet von ' + (entry.sender.displayName ?? entry.sender.username) + ' am '
						+ entry.createdOn.toLocaleDateString() + ' um '
						+ entry.createdOn.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) + ' Uhr'}
				</Typography>
				</Grid>
				<Grid item xs={11}>
				<PortalBenachrichtigungen variant="body2"	dangerouslySetInnerHTML={{__html: entry.description}}/>
				</Grid>
			</Grid>}>
		</ListItemText>
	</>

}
export const NotificationList = ({notifyAPI, markAsRead}:
	{ notifyAPI: NotificationsAPI, markAsRead: (entry: NotificationEntry) => Promise<void> }): ReactElement => {

	return <>
		{notifyAPI.entries.length > 0 ?
			<List sx={{width: '100%'}}>
				{notifyAPI.entries.map((entry, nr) =>
					<React.Fragment key={nr}>
						<ListItem alignItems="flex-start">
							<NotificationListItem entry={entry} markAsRead={markAsRead}/>
						</ListItem>
						{notifyAPI.entries.length - 1 !== nr &&
								<Divider component="li"/>
						}
					</React.Fragment>
				)}
			</List>
			:
			<Typography variant={"h5"}>
				Keine {notifyAPI.filterUnreadOnly ? 'ungelesenen' : ''} Benachrichtigungen vorhanden!
			</Typography>
		}
	</>
}
