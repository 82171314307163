import {User} from "oidc-client-ts";
import {AuthContextState} from "./AuthContext";
import {extractRoles} from "./roles";

type Action =
	| { type: "INITIALISED" | "USER_LOADED"; user: User | null | undefined }
	| { type: "USER_UNLOADED" }
	| { type: "USER_SIGNED_OUT" }
	| { type: "PROFILE_IMAGE_CHANGED", url: string }
	| { type: "IMPERSONATE", user: User | undefined }
	| { type: "ERROR"; error: Error };


/**
 * Handles how that state changes in the `useAuth` hook.
 */
export const reducer = (state: AuthContextState, action: Action): AuthContextState => {
	switch (action.type) {
		case "INITIALISED":
		case "USER_LOADED":
			return {
				...state,
				self: action.user ?? undefined,
				name: action.user?.profile.name,
				profileImgSrc: action.user?.profile.profileImgSrc as string,
				isLoading: false,
				isAuthenticated: action.user ? !action.user.expired : false,
				roles: extractRoles(action.user),
				error: undefined,
			};
		case "USER_SIGNED_OUT":
		case "USER_UNLOADED":
			return {
				...state,
				self: undefined,
				isAuthenticated: false,
				isLoading: true,
			};
		case "PROFILE_IMAGE_CHANGED":
			return {
				...state,
				profileImgSrc: action.url
			}
		case "IMPERSONATE":
			return {
				...state,
				changedUser: action.user,
				changedUserRoles: extractRoles(action.user)
			}
		case "ERROR":
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return {
				...state,
				isLoading: false,
				error: new Error(`unknown type ${action["type"] as string}`),
			};
	}
};
