import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Grid,
	Slider,
	Stack,
	Typography
} from "@mui/material";
import {useAtom} from "jotai";
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import AvatarEditor, {type Position} from 'react-avatar-editor'
import Dropzone, {FileRejection} from 'react-dropzone'
import {useAuth} from "../../../components/authprovider/useAuth";
import {useNotifyError} from "../../../components/NotifierMessageContainer";
import {notifyMessageAtom} from "../../../contexts/atoms";
import {settings} from "../../../settings";
import {defaultFetchErrorHandler} from "../../../utils/fetch-utils";

const errorMessages: {
	[key: string]: string
} = {
	"file-invalid-type": "Der Dateityp ist nicht erlaubt. Bitte verwenden Sie JPEG oder PNG-Dateien.",
	"file-too-large": "Die Datei ist zu groß.",
	"file-too-small": "Die Datei ist zu klein.",
	"too-many-files": "Zu viele Dateien hochgeladen", // unwahrscheinlich
}

function convertDataUriToBlob(dataURI: string) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	}
	else {
		byteString = unescape(dataURI.split(',')[1]);
	}
	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ia], {type: mimeString});
}

type AvatarStateType = {
	image: string | File
	allowZoomOut: boolean
	position: Position
	scale: number
	rotate: number
	borderRadius: number
	width: number
	height: number
	disableCanvasRotation: boolean
	isTransparent: boolean
	backgroundColor?: string
	showGrid: boolean
}

export const AvatarImageUpload = () => {
	const auth = useAuth();
	const [sourceUrl, setSourceUrl] = useState<string | undefined>(undefined);
	const notifyError = useNotifyError();
	const avatarUrl = settings.authorityUrl + "/avatar-provider";
	const [dataUrl, setDataUrl] = useState<string | undefined>();
	const [, setNotifyMessage] = useAtom(notifyMessageAtom);
	const editorRef = useRef<AvatarEditor>(null)
	// um die Dropzone zurücksetzen zu können
	const [dropZoneCnt, setDropZoneCnt] = useState<number>(0)
	const [avatarState, setAvatarState] = useState<AvatarStateType>({
		image: '',
		allowZoomOut: false,
		position: {x: 0.5, y: 0.5},
		scale: 1,
		rotate: 0,
		borderRadius: 0,
		width: 300,
		height: 300,
		disableCanvasRotation: false,
		isTransparent: false,
		backgroundColor: undefined,
		showGrid: false,
	})


	const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
		const scale = parseFloat(e.target.value)
		setAvatarState({...avatarState, scale})
	}

	const handleRotate = (e: ChangeEvent<HTMLInputElement>) => {
		const rotate = parseFloat(e.target.value)
		setAvatarState({...avatarState, rotate})
	}

	const handleDrag = (position: Position) => {
		setAvatarState({...avatarState, position})
	}


	const onDropRejected = (rejections: FileRejection[]) => {
		setNotifyMessage({
			message: <>
				Fehler beim Hochladen
				<ul>
					{rejections.map(({file, errors}) => {
						return (<li key={file.name}>
							{file.name} - {file.size} Bytes
							<ul>
								{errors.map(e => <li key={e.code}>{errorMessages[e.code]}</li>)}
							</ul>
						</li>)
					})
					}
				</ul>
			</>,
			severity: "error"
		});
		// DropZone reset
		setDropZoneCnt(oldvalue => oldvalue + 1);
	}

	const convertBlobToDataUri = useMemo(() => (blob: Blob) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			setSourceUrl(reader.result as string);
		}
	}, [setSourceUrl]);


	const uploadAvatar = () => {
		if (dataUrl != null) {
			const form: FormData = new FormData();
			form.append("image", convertDataUriToBlob(dataUrl))
			fetch(avatarUrl, {method: "POST", headers: {"Authorization": "Bearer " + auth.self?.access_token}, body: form})
				.then(defaultFetchErrorHandler)
				.then(() => auth.setProfileImgSrc(`${avatarUrl}?ts=${new Date().getTime()}`))
				.catch(notifyError);
		}
	}

	const onReset = () => {
		setSourceUrl('')
		setDataUrl(undefined);
		setDropZoneCnt((prevCnt) => prevCnt + 1);
		setAvatarState({
			...avatarState,
			image: '',
			position: {x: 0.5, y: 0.5},
			scale: 1,
			rotate: 0
		})
	};

	useEffect(() => {
		if (sourceUrl) {
			setAvatarState(cur => ({...cur, image: sourceUrl}))
		}
	}, [sourceUrl])

	useEffect(() => {
		fetch(avatarUrl, {headers: {"Authorization": "Bearer " + auth.self?.access_token}})
			.then(defaultFetchErrorHandler)
			.then(resp => resp.blob())
			.then(blob => convertBlobToDataUri(blob))
			.catch(err => console.error(err))
	}, [avatarUrl, convertBlobToDataUri, auth.self])

	useEffect(() => {
		setDataUrl(editorRef.current?.getImageScaledToCanvas().toDataURL());
	}, [avatarState.image, avatarState.position, avatarState.scale, avatarState.rotate])


	return <Card>
		<CardHeader title={"Profilbild"}/>
		<CardContent>
			<Stack direction={{xs: 'column', sm: 'row'}}>
				<>
					{<Dropzone
						key={dropZoneCnt}
						onError={(err) => setNotifyMessage({
							message: `Es ist ein Fehler aufgetreten: ${err.message}`,
							severity: "error"
						})}

						onDropAccepted={([image]) => image && setAvatarState({...avatarState, image: image})}
						noClick
						accept={{"image/*": [".jpeg", ".jpg", ".png"]}}
						multiple={false}
						onDropRejected={onDropRejected}
					>
						{({getRootProps, getInputProps}) => (
							<div {...getRootProps()} className="preview" style={{position: "relative"}}>
								{!avatarState.image &&
										<div style={{
											position: "absolute",
											width: '250px',
											top: '130px',
											left: '50px',
											textAlign: 'center'
										}}>
											<Typography variant={'body1'}>Ziehen Sie ein Bild hierher oder
												wählen Sie mit <b>Bild auswählen</b> ein Bild aus.</Typography>
										</div>
								}
								<AvatarEditor
									key={avatarState.image?.toString()}
									ref={editorRef}
									scale={avatarState.scale}
									rotate={avatarState.rotate}
									width={avatarState.width}
									height={avatarState.height}
									position={avatarState.position}
									backgroundColor={avatarState.backgroundColor}
									disableBoundaryChecks={avatarState.disableCanvasRotation}
									onPositionChange={handleDrag}
									borderRadius={avatarState.width / (100 / 50)}
									image={avatarState.image}
									// TODO: mf: HACK, um die Vorschauanzeige zu triggern...
									onLoadSuccess={() => handleDrag({x: 0.5, y: 0.5})}
								/>
								<input
									id="newImage"
									name="newImage"
									{...getInputProps()}
								/>
								<Grid container sx={{mt: 3}}>
									<Grid item xs={avatarState.image ? 6 : 12} sx={{textAlign: 'center'}}>
										<label htmlFor={'newImage'}>
											<Chip variant={'outlined'} color={'primary'}
													label={'Bild auswählen'} sx={{cursor: 'pointer'}}>
											</Chip>
										</label>
									</Grid>
									{avatarState.image && <>
										<Grid item xs={6} sx={{textAlign: 'center'}}>
											<Chip variant={'outlined'} color={'error'} onClick={onReset}
													label={'Bild entfernen'} sx={{cursor: 'pointer'}}>
											</Chip>
										</Grid>
										<Grid item xs={12} sx={{textAlign: 'center', pl: 3, pr: 3}}>
											<Typography>Zoom:</Typography>
											<Slider
													valueLabelDisplay="auto"
													onChange={(e) => handleScale(e as unknown as ChangeEvent<HTMLInputElement>)}
													defaultValue={1}
													min={avatarState.allowZoomOut ? 0.1 : 1}
													max={2}
													step={0.01}
											/>
										</Grid>
										<Grid item xs={12} sx={{textAlign: 'center', pl: 3, pr: 3}}>
											<Typography>Drehung:</Typography>
											<Slider
													valueLabelDisplay="auto"
													onChange={(e: Event) => handleRotate(
														e as unknown as ChangeEvent<HTMLInputElement>)}
													defaultValue={1}
													min={0}
													max={359.0}
													step={0.5}
											/>
										</Grid>
									</>}

								</Grid>
							</div>
						)}

					</Dropzone>
					}
				</>
				{dataUrl && <Box m={4}>
					<Typography variant={'body2'}>Vorschau:</Typography>
					<img
							alt="Vorschau"
							src={dataUrl}
							style={{borderRadius: '50%'}}
					/>
				</Box>}
			</Stack>
		</CardContent>
		<CardActions sx={{m: 2}}>
			{avatarState.image && <Button variant={"contained"} title={"Hochladen"}
													onClick={uploadAvatar}>Bild Hochladen</Button>}
		</CardActions>
	</Card>
}


