import type {PortalApp} from "../../interfaces/portal-api";
import * as H from "history";
import {Blocker, History, Listener, To} from "history";

type ListenerH4 = (location: H.Location, action: H.Action) => void;
export interface HistoryV4 {
	goBack(): void;
	goForward(): void;
}

export function historyWrapper(
	app: PortalApp,
	history: History,
	skipWrap:(path: To) => boolean = (() => false)
): History & HistoryV4 {
	const _old = history;
	const navType = app.navigation;
	return {
		..._old,
		location: {
			pathname: _old.location.pathname.substring(app.mountPath.length).padEnd(1, "/"),
			search: _old.location.search,
			hash: _old.location.hash,
			state: _old.location.state,
			key: _old.location.key
		},
		goBack: _old.back,
		goForward: _old.forward,
		block: (blocker: Blocker|boolean|string) => {
			switch (typeof blocker) {
				case "string":
					return _old.block(() => window.confirm(blocker));//V4-style-only
				case "boolean":
					return _old.block(() => blocker);//V4-style-only
				case "function":
					return _old.block(blocker);//V4 and V5 style
			}
		},
		push: (path: To, state?: unknown) => {
			if (skipWrap(path)) {
				_old.push(path, state);
			}
			else if (typeof path === "object") {
				_old.push({...(path as object), pathname: app.mountPath + path.pathname}, state);
			}
			else {
				_old.push(app.mountPath + path, state);
			}
		},
		replace(path: To, state?: unknown) {
			if (skipWrap(path)) {
				_old.replace(path, state);
			}
			else if (typeof path === "object") {
				_old.replace({...(path as object), pathname: app.mountPath + path.pathname}, state);
			}
			else {
				_old.replace(app.mountPath + path, state);
			}
		},
		listen(listener: Listener|ListenerH4): () => void {
			const updateWrapper = (update:H.Update) => {
				if (skipWrap(update.location) || !update.location.pathname.startsWith(app.mountPath)) {
					return;
				}
				const wrapped_location = {
						...update.location, pathname: update.location.pathname.substring(app.mountPath.length)
				}
					switch (navType) {
						case "history5":
							(listener as Listener)({location: wrapped_location, action:update.action});
							break;
						case "history4":
						default:
							(listener as ListenerH4)(wrapped_location, update.action);
							break;
					}
				}
			return _old.listen(updateWrapper);
		}
	}
}
