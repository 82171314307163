import {User, UserManager} from "oidc-client-ts";
import React from "react";

export interface AuthContextApi extends AuthContextState {

	selfUserManager: UserManager;
	effectiveUserManager: UserManager;

	hasRole: (role:string, clientId?:string) => boolean;
	effectiveUserHasRole: (role:string, clientId?:string) => boolean;

	login: () => Promise<void>;
	logout: (redirectUrl?: string) => Promise<void>;
	impersonate: (username:string) => Promise<User>;

	setProfileImgSrc: (url:string) => void;
}

export interface AuthContextState {
	/**
	 * current own user
	 */
	self?: User;
	profileImgSrc?: string;
	name?:string;
	roles?: string[];

	/**
	 * current impersonated user
	 */
	changedUser?: User;
	changedUserRoles?:string[];
	effectiveUser?: User;

	isLoading: boolean;
	isAuthenticated: boolean;

	error?: Error;
}

export const initialAuthState: AuthContextState = {
	isLoading: true,
	isAuthenticated: false,
};

export const AuthContext = React.createContext<AuthContextApi|undefined>(undefined);
