import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThLarge} from '@fortawesome/free-solid-svg-icons'
import {PortalMenuAPI} from "../hooks/usePortalMenu";
import {CategoryButton, MenuCategoriesList} from "./MenuCategories";
import {AppLink} from "./MenuItem";
import {Box, ListItemIcon, styled, useTheme} from "@mui/material";

interface Props {
	menuAPI: PortalMenuAPI,
	toggleDrawer: (setOpen?: boolean) => void
}

const CategoryIcon = styled(ListItemIcon)(() => ({
	color: 'inherit',
	minWidth: '40px',
	fontSize:'20px'
}));

export const MenuMainNavigation = (
	{menuAPI, toggleDrawer}: Props): React.ReactElement => {
	const theme = useTheme();
	return <>
		{menuAPI.menu.dashboardApp && <CategoryButton sx={{
			backgroundColor: menuAPI.menu.dashboardApp === menuAPI.currentApp
				? theme.drawerProperties.activeBackgroundColor ?? '' : ''
		}}>

			<CategoryIcon>
				<FontAwesomeIcon icon={faThLarge} onClick={() => toggleDrawer(true)} size={"sm"}/>
			</CategoryIcon>
			<Box sx={{position: 'relative', width:'100%'}}>
				<AppLink menuAPI={menuAPI} app={menuAPI.menu.dashboardApp} toggleDrawer={toggleDrawer}/>
			</Box>
		</CategoryButton>
		}
		<MenuCategoriesList menuAPI={menuAPI} toggleDrawer={toggleDrawer}/>
	</>
}
