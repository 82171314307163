import {InMemoryWebStorage, User, UserManager, UserManagerSettings, WebStorageStateStore} from "oidc-client-ts";
import {FC, PropsWithChildren, ReactElement, useCallback, useEffect, useReducer, useRef, useState} from "react";
import {settings} from "../../settings";
import {defaultResponseErrorCheck} from "../../utils/fetch-utils";
import {AuthContext, initialAuthState} from "./AuthContext";
import {reducer} from "./reducer";
import {decodeToken, hasAuthParams, signinError} from "./utils";

export interface OidcAuthProviderProps extends UserManagerSettings {
}

export const OidcAuthProvider:FC<PropsWithChildren<OidcAuthProviderProps>> = (props) : ReactElement => {

	const [selfUserManager] = useState(() => new UserManager({
		...props,
		userStore: new WebStorageStateStore({store: window.localStorage})
	}));
	const [otherUserManager] = useState(() => new UserManager({
		...props,
		userStore: new WebStorageStateStore({prefix:  "exchange.", store: new InMemoryWebStorage()})
	}));
	const [state, dispatch] = useReducer(reducer, initialAuthState);
	const didInitialize = useRef(false);

	useEffect(() => {
		if (didInitialize.current) {
			return;
		}
		didInitialize.current = true;

		void (async (): Promise<void> => {
			// sign-in
			let user: User | void | null = undefined;
			try {
				// check if returning back from authority server
				if (hasAuthParams()) {
					user = await selfUserManager.signinCallback();
					onSigninCallback();
				}
				user = !user ? await selfUserManager.getUser() : user;
				dispatch({type: "INITIALISED", user});
			}
			catch (error) {
				dispatch({type: "ERROR", error: signinError(error)});
			}
		})();

	}, [selfUserManager])

	// register to userManager events
	useEffect(() => {
		const unsubscriber = new Array<()=>void>();

		// event UserLoaded (e.g. initial load, silent renew success)
		const handleUserLoaded = (user: User) => {
			dispatch({ type: "USER_LOADED", user });
		};
		unsubscriber.push(selfUserManager.events.addUserLoaded(handleUserLoaded));

		// event UserUnloaded (e.g. userManager.removeUser)
		const handleUserUnloaded = () => {
			dispatch({ type: "USER_UNLOADED" });
		};
		unsubscriber.push(selfUserManager.events.addUserUnloaded(handleUserUnloaded));

		// event UserSignedOut (e.g. user was signed out in background (checkSessionIFrame option))
		const handleUserSignedOut = () => {
			dispatch({ type: "USER_SIGNED_OUT" });
		};
		unsubscriber.push(selfUserManager.events.addUserSignedOut(handleUserSignedOut));

		// event SilentRenewError (silent renew error)
		const handleSilentRenewError = (error: Error) => {
			if (error.name === "ErrorResponse") {
				const res = confirm("Ihre Session ist nicht mehr aktiv! Sie müssen sich erneut anmelden!")
				res ? selfUserManager.signinRedirect({redirect_uri:window.location.href})
					: selfUserManager.signoutRedirect();
			}
			else {
				dispatch({type: "ERROR", error});
			}
		};
		unsubscriber.push(selfUserManager.events.addSilentRenewError(handleSilentRenewError));

		return () => unsubscriber.forEach(unsubscribe => unsubscribe())
	}, [selfUserManager])

	const hasRole = useCallback((role:string, clientId?:string):boolean => {
		return state.roles?.length ? state.roles.includes(`${role}@${clientId ?? settings.clientId}`) : false
	}, [state.roles]);

	const effectiveUserHasRole = useCallback((role:string, clientId?:string):boolean => {
		if (state.changedUser) {
			return state.changedUserRoles?.length ?
				state.changedUserRoles.includes(`${role}@${clientId ?? settings.clientId}`) : false;
		}
		return hasRole(role, clientId);
	}, [state.changedUser, state.changedUserRoles, hasRole]);

	const login = useCallback(() => {
		return selfUserManager.signinRedirect()
	}, [selfUserManager])

	const logout = useCallback(async (redirectUrl?:string) => {
		await otherUserManager.revokeTokens();
		return selfUserManager.signoutRedirect({
			id_token_hint:state.self?.id_token,
			post_logout_redirect_uri: redirectUrl
		})
	}, [selfUserManager, otherUserManager, state.self])

	const setProfileImgSrc = useCallback((url:string) => {
		dispatch({type:"PROFILE_IMAGE_CHANGED", url: url})
	}, [])

	const impersonate = useCallback(async (username:string) => {
		if (!state?.self?.access_token) throw new Error("no access_token available");
		const url = await selfUserManager.metadataService.getTokenEndpoint(false);
		const response = await impersonateTo(url, state.self.access_token, username);
		const expiresAt = new Date().getTime() / 1000 + response.expires_in;
		const user = new User({
			...response,
			//TODO: HACK: wir füllen die UserInfo(profile) bereits jetzt, da oidc-client-ts das erst nach dem Refresh selbst erledigt.
			profile: decodeToken(response.access_token),
			expires_at: expiresAt, expires_in: undefined
		});
		await otherUserManager.revokeTokens();//remove old tokens if present
		await otherUserManager.storeUser(user);//store new tokens
		//Important: getUser() starts refresh timers
		otherUserManager.getUser()
			.then(user => console.log("Exchanged to user", user))
			.catch(console.error);
		dispatch({type:"IMPERSONATE", user})
		return user;
	}, [otherUserManager, state?.self?.access_token,selfUserManager.metadataService])

	return (
		<AuthContext.Provider
			value={{
				...state,
				login, logout, impersonate,
				hasRole, effectiveUserHasRole,
				setProfileImgSrc,

				selfUserManager,
				effectiveUser: state.changedUser ?? state.self,
				effectiveUserManager: state.changedUser ? otherUserManager : selfUserManager,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
}

const onSigninCallback = (): void => {
	window.history.replaceState({}, document.title, window.location.pathname)
}


function impersonateTo(tokenEndpointUrl:string, ownerToken:string, requestedSubject:string) {
	const formData = new FormData()
	formData.append("grant_type", "urn:ietf:params:oauth:grant-type:token-exchange");
	formData.append("subject_token", ownerToken);
	formData.append("requested_token_type", "urn:ietf:params:oauth:token-type:refresh_token");
	formData.append("client_id", settings.clientId);
	formData.append("audience", settings.clientId);
	formData.append("requested_subject", requestedSubject);
	const data = new URLSearchParams();
	formData.forEach((value, key) => data.append(key, value as string));
	return fetch(tokenEndpointUrl, {
		method: "POST",
		credentials: "include",
		headers: {"Authorization": "Bearer " + ownerToken},
		body: data
	})
		.then(defaultResponseErrorCheck(`Es ist ein Fehler aufgetreten beim Wechsel zum Nutzer '${requestedSubject}'! Details:`))
		.then(response => response.json())
}
