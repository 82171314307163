import {BvoTheme} from "@intentus/bvo-theme-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import {alpha, Box, Button, Menu, MenuItem, Theme} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../authprovider/useAuth";


const ProfilImg = styled(Box)(({theme}) => ({
	width: "25px",
	height: '25px',
	borderRadius: '25px',
	lineHeight: '25px',
	[theme.breakpoints.up('sm')]: {
		width: "40px",
		height: '40px',
		borderRadius: '40px',
		lineHeight: '40px',
		margin: '7px',
	},
	backgroundColor: theme.palette.background.default,
	display: 'inline-block',
	float: 'right',
	overflow: 'hidden',
	color: theme.palette.text.primary,
	textAlign: 'center',
	fontSize: '16px',
	'img': {
		height: '25px',
		[theme.breakpoints.up('sm')]: {
			height: "40px"
		}
	}
}));
const Username = styled(Box)(({theme}) => ({
	[theme.breakpoints.down('sm')]: {
		display: 'none'
	}
}));
const MenuButton = styled((Button))(({theme}: {theme: Theme & BvoTheme}) => ({
	fontFamily: 'inherit',
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: theme.toolbarProperties.backgroundColor,
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.black, 0.05),
	},
	color: theme.toolbarProperties.color,
	height: theme.toolbarProperties.height,
	[theme.breakpoints.down('sm')]: {
		paddingLeft: 0,
		'.MuiButton-startIcon': {
			marginRight: 0
		}
	}
}));

const MenuLink = styled((Link))(({theme}) => ({
	textDecoration: 'none',
	fontSize: theme.typography.body1.fontSize,
	color: theme.palette.text.primary
}));

const MenuLinkHighlight = styled((Link))(({theme}) => ({
	textDecoration: 'none',
	fontSize: theme.typography.body1.fontSize,
	color: theme.palette.text.secondary
}));

export const ToolbarProfile = ({profilePath}:{profilePath:string}): React.JSX.Element => {
	const auth = useAuth();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleMenuClose = () => setAnchorEl(null)
	const isMenuOpen = Boolean(anchorEl);
	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

	if (auth.isAuthenticated) {
		let userName = auth.name ?? 'Unbekannter Name';
		if (auth.changedUser) {
			userName += ` (als ${auth.changedUser.profile.name} ${auth.changedUser.profile.preferred_username})`;
		}
		const nameInitials = userName.split(' ', 2).map(name => name.charAt(0)).join("")
		return <>
			<Box>
				<MenuButton variant={'text'} color={'primary'} onClick={handleMenuOpen} startIcon={
					<KeyboardArrowDownIcon/>
				}>
					<Username>{userName}</Username>
					<ProfilImg>
						{auth.profileImgSrc !== undefined ?
							<img src={auth.profileImgSrc} alt={'Profilbild: ' + userName}/> : nameInitials
						}
					</ProfilImg>
				</MenuButton>
				<Menu anchorEl={anchorEl} keepMounted open={isMenuOpen} onClose={handleMenuClose}>
					<MenuItem>
						<MenuLink to={profilePath} onClick={handleMenuClose}>
							<Person2Icon fontSize={'small'} sx={{mr: 1, float: 'left'}}/>Mein Profil
						</MenuLink>
					</MenuItem>
					<MenuItem>
						<MenuLinkHighlight onClick={() => auth.logout(window.origin)} to="/" title={"Angemeldet als " + auth.name}
												 className={'portal_menuHighlightLink'}>
							<LogoutIcon sx={{mr: 1, float: 'left'}}/>Abmelden</MenuLinkHighlight>
					</MenuItem>
				</Menu>
			</Box>
		</>
	}
	else {
		return <MenuLinkHighlight onClick={auth.login} to="/" title={"Nicht angemeldet"}>
			<LoginIcon>Anmelden</LoginIcon>
		</MenuLinkHighlight>
	}


}
