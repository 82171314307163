import {defaultFetchErrorHandler} from "../../utils/fetch-utils";
import type {ManifestType} from "../../interfaces/portal-api";

interface Assets {
	js: string[];
	css: string[];
}


export const ManifestLoader = (baseUrl: string, format: ManifestType):Promise<Assets> => {

	let parser;
	let url = baseUrl + "/asset-manifest.json";
	switch (format) {
		case "webpack":
			url = baseUrl + "/asset-manifest.json";
			parser = webpackFormatParser;
			break;
		case "vite":
			url = baseUrl + "/manifest.json";
			parser = viteFormatParser;
			break;
		case "rollup":
			throw new Error("Rollup Manifest format is not-yet-implemented")
	}
	return fetch(url)
		.then(defaultFetchErrorHandler)
		.then(res => res.json())
		.then(json => parser(baseUrl, json));
}
interface WebpackManifestFormat  {
	files: {
		[filename:string]: string;
	}
	entrypoints: string[];
}
/**
 * Webpack Format:
 *
 */
export function webpackFormatParser(baseUrl:string, manifest:WebpackManifestFormat):Assets {
	return {
		js: manifest.entrypoints.filter(s => s.endsWith(".js")).map(s => baseUrl + "/" + s),
		css: manifest.entrypoints.filter(s => s.endsWith(".css")).map(s => baseUrl + "/" + s),
	}
}

export interface ViteManifestFile {
	[entry:string]: ViteManifestEntry;
}
interface ViteManifestEntry {
	file: string;
	src?: string;
	isEntry?:boolean;
	//isDynamicEntry?:boolean;
	imports?: string[];
	css?: string[];
}

export function viteFormatParser(baseUrl:string, manifest:ViteManifestFile):Assets {
	const entry = Object.values(manifest).filter(e => e.isEntry === true).shift();
	if (entry) {
		const dynImports = entry.imports?.map(x => manifest[x]).map(i => baseUrl + "/" + i.file) ?? [];
		return {
			js:[...dynImports, baseUrl + "/" + entry.file],
			css:entry.css?.map(css => baseUrl + "/" + css) ?? []
		}
	}
	return {
		js:[],
		css:[]
	}
}