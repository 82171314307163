import React, {useCallback, useEffect, useMemo} from 'react';
import {Sidebar} from "./menu/Sidebar";
import {PortalMenuAPI} from "./hooks/usePortalMenu";
import {Toolbar} from "./toolbar/Toolbar";
import {styled, Theme, useTheme} from "@mui/material";
import {BvoTheme} from "@intentus/bvo-theme-react";
import {useScreenType} from "./hooks/useScreenType";
import {useAtom} from "jotai";
import {drawerExpandedAtom} from "../contexts/atoms";

function PortalLayout({menuAPI, children}: { menuAPI: PortalMenuAPI, children: React.ReactNode}) {

	const theme = useTheme<BvoTheme & Theme>();
	const screenType = useScreenType();
	const [ expanded, setExpanded ] = useAtom(drawerExpandedAtom);

	const drawerWidth = useMemo(() => {
		switch (screenType) {
			case 'mobile': return expanded ? '100vw !important' : '0 !important';
			case 'tablet': return expanded ? theme.drawerProperties.widthDesktop + ' !important' : theme.drawerProperties.widthTablet + ' !important';
			case 'desktop':return expanded ? theme.drawerProperties.widthDesktop + ' !important' : theme.drawerProperties.widthTablet + ' !important'
		}
	}, [expanded, screenType, theme.drawerProperties.widthDesktop,  theme.drawerProperties.widthTablet])

	const toggleDrawer = useCallback((setOpen?: boolean) => {
		setExpanded(cur => setOpen !== undefined ? setOpen : !cur)
	},[setExpanded])

	useEffect(() => {
		setExpanded(screenType === 'desktop');
	}, [screenType, setExpanded ]);

	return <>
		<Sidebar menuAPI={menuAPI} toggleDrawer={toggleDrawer} drawerWidth={drawerWidth} padding={theme.drawerProperties.padding}/>
		<Toolbar menuAPI={menuAPI} toggleDrawer={toggleDrawer}/>
		<Section sx={{paddingLeft: drawerWidth}} >{children}</Section>
	</>
}

const Section = styled('section')(({theme}: {theme: BvoTheme}) => ({
	letterSpacing: '1px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'stretch',
	alignItems: 'stretch',
	height: '100%',
	paddingTop: theme.toolbarProperties.height,
	boxSizing: 'border-box',
	position: 'relative',
	overflow: 'auto',
}));

export default PortalLayout;
