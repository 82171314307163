import React, {useState} from "react";
import {Link} from "react-router-dom";
import {PortalMenuAPI} from "../hooks/usePortalMenu";
import {alpha, Box, Button, Menu, MenuItem, Theme} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {styled} from "@mui/material/styles";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import {BvoTheme} from "@intentus/bvo-theme-react";

const MenuButton = styled((Button))(({theme}:{theme: Theme & BvoTheme}) => ({
	fontFamily: 'inherit',
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: theme.toolbarProperties.backgroundColor,
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.black, 0.05),
	},
	color: (theme as BvoTheme).toolbarProperties.color,
	height: (theme as BvoTheme).toolbarProperties.height,
	[(theme as Theme).breakpoints.down('sm')]: {
		paddingLeft: 0,
		paddingRight: 0,
		'.MuiButton-endIcon': {
			marginLeft: 0
		}
	}
}));

const MenuLink = styled((Link))(({theme}) => ({
	textDecoration: 'none',
	fontSize: theme.typography.body1.fontSize,
	color: theme.palette.text.primary
}));

export const ToolbarActiveApps = ({menuAPI}: {
	menuAPI: PortalMenuAPI,
}): JSX.Element => {

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleMenuClose = () => setAnchorEl(null)
	const isMenuOpen = Boolean(anchorEl);
	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

	return <>
		<MenuButton variant={'text'}  color={'primary'} onClick={handleMenuOpen} endIcon={<KeyboardArrowDownIcon/>}>
			<Box sx={{display: {xs: 'none', sm: 'block'}}}>Offene Apps</Box>
			<FilterNoneIcon sx={{display: {xs: 'block', sm: 'none'}}} fontSize={'small'}/>
		</MenuButton>
		<Menu anchorEl={anchorEl} keepMounted open={isMenuOpen} onClose={handleMenuClose}>
			{menuAPI.openApps.map((app, index) =>
				<MenuItem key={index}>
					<MenuLink to={menuAPI.getAppLocation(app)} title={app.name} onClick={handleMenuClose}>
						{app.name}
					</MenuLink>
				</MenuItem>)
			}
		</Menu>
	</>
}
