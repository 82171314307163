import {useCallback, useEffect, useMemo} from "react";
import type {OpenAppsLocationMap, PortalApp, PortalMenu} from "../../interfaces/portal-api";
import {useAtom} from "jotai";
import {openAppsAtom, openAppsLocationAtom} from "../../contexts/atoms";
import {useLocation, useNavigate} from "react-router";
import * as H from "history";
import {useMenuLoader} from "./useMenuLoader";
import {portalHistory} from "../PortalNavigationRouter";
import {historyWrapper} from "../frontends/HistoryWrapper";
import {skipPathWrapping} from "../frontends/AppComponent";

export interface PortalMenuAPI {
	menu: PortalMenu;
	currentApp?: PortalApp;
	openApps: PortalApp[];
	getAppLocation: (app:PortalApp) => H.Location;
	getAppHistory: (app:PortalApp) => H.History;
	isAppOpen: (app:PortalApp) => boolean;
	startApp: (app:PortalApp) => void;
	closeApp: (app:PortalApp) => void;
	/**
	 * Close all(i.e. other) Apps, except the current open
	 */
	closeAllApps: () => void;
}

//TODO: elegantere Javascript Lösung für das Object-Equality Problem finden..
export function appEquals(src: PortalApp, dst?: PortalApp):boolean {
	return dst ? src.mountPath === dst.mountPath : false;
}
function contains(list: PortalApp[], target:PortalApp) {
	return list.filter(app => appEquals(app, target)).length > 0;
}

export const usePortalMenu = ():PortalMenuAPI => {
	const location = useLocation();
	const navigate = useNavigate();
	const menu = useMenuLoader();//Achtung: keine Object.is Stabilität vorhanden!

	const [openApps, setOpenApps] = useAtom(openAppsAtom);
	const [openAppsLocation, setOpenAppsLocation] = useAtom(openAppsLocationAtom);
	const currentApp = useMemo(() => menu.apps.filter(app => app.type !== "extern"
		&& location.pathname.startsWith(app.mountPath)).shift(),
		[menu.apps, location.pathname]);

	const isAppOpen = useCallback((targetApp:PortalApp) => {
		if (targetApp.type === "extern") return false;
		return openApps.find(app => app.mountPath === targetApp.mountPath) !== undefined;
	}, [openApps]);

	const startApp = (targetApp:PortalApp) => {
		setOpenApps(cur => !contains(cur, targetApp) ? [...cur, targetApp] : cur);
		if (currentApp && !appEquals(currentApp, targetApp)) {
			// Save current location (including state, ...) for currentApp before the switch to targetApp was done (externally thru history-location change)!
			const entry:OpenAppsLocationMap = {};
			entry[currentApp.mountPath] = JSON.parse(JSON.stringify(location));//deep clone HACK
			setOpenAppsLocation(cur => ({...cur, ...entry}));
		}
	}

	//remove app from openApps and delete the state. PUSH to dashboard. TODO: change to push-to-last-one
	const closeApp = (targetApp:PortalApp) => {
		setOpenApps(cur => cur.filter(app => !appEquals(app, targetApp)))
		setOpenAppsLocation(cur => {
			delete cur[targetApp.mountPath];
			return {...cur}
		})
		if (appEquals(targetApp, currentApp)) {
			const app = openApps.filter(app => !appEquals(app, targetApp))?.[0];
			if (app) {
				const appLoc = getAppLocation(app);
				navigate(appLoc, {state: appLoc.state});
			}
			else {
				navigate(menu.dashboardPath);
			}
		}
	}

	const closeAllApps = () => {
		setOpenAppsLocation({});
		setOpenApps(currentApp ? [currentApp] : []);
	}

	//get last Location state of an app
	const getAppLocation = (targetApp:PortalApp):H.Location => {
		const appLoc = openAppsLocation[targetApp.mountPath];
		if (appLoc) return appLoc;
		if (targetApp === currentApp) return location;
		if (location.pathname.startsWith(targetApp.mountPath)) {
			//Deep-Link into app
			return location;
		}
		const url = new URL(targetApp.mountPath + (targetApp.startPage ?? ""), window.origin)
		return {
			pathname: url.pathname,
			search: url.search,
			hash: url.hash,
			state: targetApp.initialState ?? {},
			key: "default"
		};
	}

	//get a history which hides all non-app (from other apps) location-changes
	const getAppHistory = (app:PortalApp):H.History => {
		const appLocation = getAppLocation(app);
		return historyWrapper(app, {...portalHistory, location: appLocation}, skipPathWrapping)
	}

	//Check for implicit open an app by location, TODO: nicht sonderlich elegant...
	useEffect(() => {
		if (currentApp && !isAppOpen(currentApp)) {
			setOpenApps(cur => !contains(cur, currentApp) ? [...cur, currentApp] : cur);
			const newAppLocation = JSON.parse(JSON.stringify(location));
			newAppLocation.state = tryToMergeState(location.state, currentApp.initialState) ?? location.state ?? currentApp.initialState;
			setOpenAppsLocation(cur => ({...cur, [currentApp.mountPath]: newAppLocation}));
		}
	}, [currentApp, isAppOpen, setOpenApps, setOpenAppsLocation, location])

	return {
		menu,
		currentApp,
		openApps,
		getAppLocation,
		getAppHistory,
		isAppOpen,
		startApp,
		closeApp,
		closeAllApps
	};
}

function tryToMergeState(state1: unknown, state2: unknown) {
	if (typeof state1 === 'object' && typeof state2 == 'object') {
		return {...state1, ...state2}
	}
}
