import {useCallback, useEffect, useState} from "react";
import type {PortalMenu} from "../../interfaces/portal-api";
import {settings} from "../../settings";
import {defaultFetchErrorHandler} from "../../utils/fetch-utils";
import {useAuth} from "../authprovider/useAuth";
import {useNotifyError} from "../NotifierMessageContainer";

export const useMenuLoader = () => {
	const auth = useAuth();
	const notifyError = useNotifyError();
	const [menuEntries, setMenuEntries] = useState<PortalMenu>({dashboardPath:"/welcome", categories:{}, apps:[]});

	const filterVisible = useCallback((loadedMenu: PortalMenu): PortalMenu => {
		const menu = {...loadedMenu}
		menu.apps = menu.apps.filter(app => {
			return app.requiredRole === undefined || auth.hasRole(app.requiredRole.role, app.requiredRole.clientId)
		})
		menu.dashboardApp = menu.apps.filter(app => menu.dashboardPath.includes(app.mountPath)).shift();
		menu.profileApp = menu.apps.filter(app => menu?.profilePath?.includes(app.mountPath)).shift();
		return menu;
	}, [auth]);


	useEffect(() => {
		const abortCtrl = new AbortController();
		if (auth.isAuthenticated) {
			fetch(settings.portal_config_url, {signal: abortCtrl.signal})
				.then(defaultFetchErrorHandler)
				.then(res => res.json())
				.then(apps => filterVisible(apps))
				.then(apps => setMenuEntries(apps))
				.catch(notifyError)
				.catch(err => console.error(err));
		}
		return () => abortCtrl.abort()
	}, [auth.isAuthenticated, filterVisible, setMenuEntries,notifyError]);

	return menuEntries;
}
