import React from "react";
import {ToolbarActiveApps} from "./ToolbarActiveApps";
import {ToolbarProfile} from "./ToolbarProfile";
import {PortalMenuAPI} from "../hooks/usePortalMenu";
import {ToolbarNotifications} from "./ToolbarNotifications";
import {alpha, AppBar, Box, IconButton, Theme, useTheme} from "@mui/material";
import MuiToolbar from "@mui/material/Toolbar";
import MenuIcon from '@mui/icons-material/Menu';
import {ArrowUpward, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {useScreenType} from "../hooks/useScreenType";
import {BvoTheme} from "@intentus/bvo-theme-react";
import {useAtom} from "jotai";
import {drawerExpandedAtom} from "../../contexts/atoms";

export const Toolbar = ({menuAPI, toggleDrawer}: {
	menuAPI: PortalMenuAPI,
	toggleDrawer: (setOpen?: boolean) => void,
	toolbarWidth?: string
}): JSX.Element => {

	const screenType = useScreenType();
	const theme = useTheme();
	const [ expanded ] = useAtom(drawerExpandedAtom);

	return <AppBar position="fixed" color={'primary'}>
		<StyledToolbar>
			{screenType === 'mobile'
				?
				<MobilMenuButton toggleDrawer={toggleDrawer} expanded={expanded}/>
				:
				<>
					{ expanded ?
						<MenuCircle color="inherit" onClick={() => {
							toggleDrawer();
						}} sx={{left: theme.drawerProperties.widthDesktop}}>
							<KeyboardArrowLeft fontSize={'small'}/>
						</MenuCircle>
						:
						<MenuCircle color="inherit" onClick={() => toggleDrawer()} sx={{left: theme.drawerProperties.widthTablet}}>
							<KeyboardArrowRight fontSize={'small'}/>
						</MenuCircle>
					}
				</>
			}
			<LogoBox/>
			{menuAPI.openApps.length !== 0 && <ToolbarActiveApps menuAPI={menuAPI}/>}
			<Box sx={{flexGrow: 1}}/>
			{menuAPI.menu.notifications?.notificationFetchUrl !== undefined && <ToolbarNotifications menuAPI={menuAPI}/>}
			<ToolbarProfile profilePath={menuAPI.menu.profilePath ?? "/profile"} />
		</StyledToolbar>
	</AppBar>
}

export const MobilMenuButton = ({toggleDrawer, expanded}: {
	toggleDrawer: (setOpen?: boolean) => void,
	expanded: boolean,
}): React.ReactElement => {
	return <>
		{expanded ?
			<MenuIconArrowButton
				color="inherit" onClick={() => toggleDrawer()}>
				<ArrowUpward/>
			</MenuIconArrowButton>
			:
			<MenuIconButton color={'inherit'} onClick={() => toggleDrawer()}>
				<MenuIcon color={'inherit'}/>
			</MenuIconButton>}
	</>
}

const MenuCircle = styled(IconButton)(({theme}: { theme: Theme & BvoTheme }) => ({
	padding: '2px',
	backgroundColor: theme.toolbarProperties.sidebarIconBackgroundColor,
	boxShadow: theme.toolbarProperties.sidebarIconShadow,
	border: '1px solid ' + theme.toolbarProperties.sidebarIconBorderColor,
	bottom: '100px',
	marginLeft: '-12px',
	position: 'fixed',
	color: theme.toolbarProperties.sidebarIconTextColor,
	'&:hover': {
		backgroundColor: theme.toolbarProperties.sidebarIconBackgroundColor,
	},
}));

const StyledToolbar = styled(MuiToolbar)(({theme}) => ({
	paddingRight: 0,
	paddingLeft: 0,
	boxShadow: theme.toolbarProperties.shadow??'none',
	[theme.breakpoints.up('sm')]: {
		paddingLeft: 0,
		paddingRight: 0
	}
}));
const MenuIconButton = styled(IconButton)(({theme}) => ({
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(2),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.black, 0.05),
	}
}));
const MenuIconArrowButton = styled(IconButton)(({theme}) => ({
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(2),
	backgroundColor: alpha(theme.palette.common.black, 0.05),
}));
const LogoBox = styled(Box)(({theme}) => ({
	float: 'left',
	width: theme.toolbarProperties.logoWidth,
	height: theme.toolbarProperties.logoHeight,
	backgroundImage: 'url(' + theme.toolbarProperties.logoBackgroungImage + ')',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',

	marginLeft: '20px',
	[theme.breakpoints.down('sm')]: {
		marginLeft: 0,
	}
}));
