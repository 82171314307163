import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BvoTheme} from "@intentus/bvo-theme-react";
import {Box, ListItem, ListItemText, styled, Theme, useTheme} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import type {PortalApp} from "../../interfaces/portal-api";
import {useAuth} from "../authprovider/useAuth";
import {PortalMenuAPI} from "../hooks/usePortalMenu";
import {useScreenType} from "../hooks/useScreenType";



const StyledList = styled(ListItem)(({theme}: { theme: BvoTheme }) => ({
	fontSize: '90%',
	'&:hover':{
		backgroundColor: theme.drawerProperties.hoverBackgroundColor + ' !important',
		color: theme.drawerProperties.hoverColor ?? 'inherit',
	}
}));
const StyledListItemText = styled(ListItemText)(({theme}: { theme: BvoTheme }) => ({
	paddingLeft: '16px',
	marginLeft: '24px',
	marginTop: theme.drawerProperties.menuTextMarginVertical??'4px',
	marginBottom: theme.drawerProperties.menuTextMarginVertical??'4px',
	borderRadius: theme.drawerProperties.activeBorderRadius ?? 0,
	'&:hover':{
		backgroundColor: theme.drawerProperties.activeBackgroundColor ?? '',
	}
}));
export const MenuItem = ({menuAPI, app, toggleDrawer}: Props): JSX.Element => {

	const theme = useTheme();

	return <StyledList dense>
		<StyledListItemText
			sx={{backgroundColor: app === menuAPI.currentApp ? theme.drawerProperties.activeBackgroundColor ?? '' : ''}}
			inset
			disableTypography
			primary={
				<Box sx={{position: 'relative', width:'100%'}}>
					<AppLink menuAPI={menuAPI} app={app} toggleDrawer={toggleDrawer}/>
					{menuAPI.isAppOpen(app) &&
							<CloseIcon icon={faTimes} size={"xs"}
										  sx={theme.drawerProperties.closeButtonPosition}
										  className={app === menuAPI.currentApp ? "portal_activeApp" : ""}
										  title={app.name + 'App schließen'} onClick={() => {
								menuAPI.closeApp(app);
							}}/>
					}
				</Box>
			}/>
	</StyledList>
}

export const AppLink = ({menuAPI, app, toggleDrawer}: Props) => {

	const auth = useAuth();
	const screenType = useScreenType();
	const closeDrawerWhenMobile = () => {
		if (screenType === 'mobile') {
			toggleDrawer()
		}
	}
	const role = app.requiredRole
	const appAvailable = role ? auth.effectiveUserHasRole(role.role, role.clientId) : true;

	switch (app.type) {
		case "intern":
			return <StyledDomLink to={app.mountPath} onClick={closeDrawerWhenMobile}
										 style={{textDecoration: !appAvailable ? "line-through" : "none"}}
										 className={app === menuAPI.currentApp ? "portal_activeApp" : ""}>
				{app.name}
			</StyledDomLink>
		case "extern":
			return <StyledLink href={app.url} onClick={closeDrawerWhenMobile} rel="noreferrer" target="_blank"
									 style={{textDecoration: !appAvailable ? "line-through" : "none"}}>
				{app.name}
			</StyledLink>
		default:
			//TODO: react-router-dom Link hat den State von der to=$location abgespalten
		{
			const appLocation = menuAPI.getAppLocation(app);
			return <StyledDomLink state={appLocation.state} to={appLocation}
										 onClick={() => {
											 menuAPI.startApp(app);
											 closeDrawerWhenMobile()
										 }}
										 style={{textDecoration: !appAvailable ? "line-through" : "none"}}
										 className={app === menuAPI.currentApp ? "portal_activeApp" : ""}>
				{app.name}
			</StyledDomLink>;
		}
	}

}

const StyledLink = styled('a')(({theme}: { theme: BvoTheme }) => ({
	color: theme.drawerProperties.color
}));

const StyledDomLink = styled(Link)(({theme}: { theme: BvoTheme }) => ({
	color: theme.drawerProperties.color,
	position: "relative",
	width: theme.drawerProperties.linkWidth ?? 'auto',
	display: 'inline-block',
	'&.portal_activeApp': {
		color: theme.drawerProperties.activeColor,
		'&:after': {
			content: "''",
			backgroundColor: theme.drawerProperties.activeUnderlineColor ?? theme.drawerProperties.color,
			width: '100%',
			height: '0.0625rem',
			display: theme.drawerProperties.activeUnderlineColor ? 'inline-block' : 'none',
			position: 'absolute',
			bottom: '0',
			left: 0,
		}
	},
	'&:hover': {
		'&:after': {
			content: "''",
			backgroundColor: theme.drawerProperties.activeUnderlineColor ?? theme.drawerProperties.color,
			width: '100%',
			height: '0.0625rem',
			display: 'inline-block',
			position: 'absolute',
			bottom: '0',
			left: 0,
		}
	},
}));

const CloseIcon = styled(FontAwesomeIcon)(({theme}: { theme: Theme & BvoTheme }) => ({
	marginLeft: theme.spacing(3),
	cursor: 'pointer',
	'&.portal_activeApp': {
		color: theme.drawerProperties.activeColor,
	},
}));

interface Props {
	menuAPI: PortalMenuAPI,
	app: PortalApp,
	toggleDrawer: (setOpen?: boolean) => void
}
