import {History, Path, To} from "history";
import React, {ReactElement} from "react";
import type {PortalApp} from "../../interfaces/portal-api";
import {ErrorBoundary} from "../ErrorBoundary";
import {PortalRedirect} from "../PortalRedirect";
import IFrameUrlApp from "./IFrameUrlApp";
import SPAIFrameApp from "./SPAIFrameApp";
import WebpackNativeApp from "./WebpackNativeApp";


export function skipPathWrapping(path: To): boolean {
	if ((path as Path).pathname) {
		return (path as Path).pathname?.match(/^\/(redirect|logout)/) != null;
	}
	else {
		return path.toString()?.match(/^\/(redirect|logout)/) != null;
	}
}

export const AppComponent = ({app, history, visible}: { app: PortalApp, history:  History, visible: boolean }): ReactElement => {
	let frontend = undefined;
	switch (app.type) {
		case "webpack":
			frontend = visible && <WebpackNativeApp app={app} history={history}/>
			break;
		case "webpackiframe":
		case "spa-iframe":
			frontend = <SPAIFrameApp visible={visible} app={app} history={history}/>
			break;
		case "extern":
			frontend = visible && <PortalRedirect toUrl={app.url} target={"_blank"}/>
			break;
		case "iframe":
			frontend = <IFrameUrlApp visible={visible} app={app}/>;
			break
		default:
			frontend = '';
	}
	return <ErrorBoundary>{frontend}</ErrorBoundary>
}
