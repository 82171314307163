import React from "react";
import {BvoAppBar} from "@intentus/bvo-theme-react";
import {Box, Container, Divider, Stack, Typography} from "@mui/material";
import {UserAccountForm} from "./components/UserAccountForm";
import {AvatarImageUpload} from "./components/AvatarImageUpload";


export const ProfilePage = () => {
	return <Box display={"flex"} flexDirection={"column"}>
		<BvoAppBar barContentLeft={<Typography variant="h2" noWrap>Mein Profil</Typography>}/>
		<Container>
			<Stack spacing={2} direction={"column"} divider={<Divider/>}>
				<UserAccountForm/>
				<AvatarImageUpload/>
			</Stack>
		</Container>

	</Box>
}

