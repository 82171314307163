import {BvoLoadingSpinner} from "@intentus/bvo-theme-react";
import {Backdrop} from "@mui/material";
import * as H from "history";
import {UserManager} from "oidc-client-ts";
import React, {useCallback, useMemo, useState} from 'react';
import type {PortalApp} from "../../interfaces/portal-api";
import {log} from "../../settings";
import {useAuth} from "../authprovider/useAuth";
import {loadSPA, MountFunction, MountFunctionNG, MountFunctionOld, waitForDomReady} from "../hooks/SPALoader";
import styles from './SPAIFrameApp.module.css';

const SPAIFrameApp = ({app, visible, history}: { app: PortalApp, visible: boolean, history: H.History}) => {
	const auth = useAuth();
	const [showBackdrop, setShowBackdrop] = useState(false);

	const loadAndRunSPAApp = useCallback((event: React.SyntheticEvent<HTMLIFrameElement>) => {
		const iframe = event.target as HTMLIFrameElement;
		if (iframe?.contentWindow) {
			log(`loadSPA ${app.name}`, app);
			setShowBackdrop(true);
			loadSPA(app.url, iframe.contentWindow.document, app.jsConfigUrl, app.manifestType)
				.then(() => waitForDomReady(iframe.contentWindow!, app.mountFunc!))
				.then(mountFunc => executeMountFunc(mountFunc, app, 'root', auth.effectiveUserManager, history))
				.finally(() => setShowBackdrop(false))
				.catch(error => alert(error.message));
		}
	}, [app, history, setShowBackdrop, auth.effectiveUserManager]);

	const sourceDoc = useMemo(() => `<html lang="de">
						<head>
							<meta name="description" content="BVO-Frontend"/>
							<title>${app.name}</title>
							<base href="${app.url}/">
							${app.jsConfigUrl ? `<script id="${app.jsConfigUrl}" src="${app.jsConfigUrl}"></script>` : ""}
						</head>
						<body><div id="root"/></body>
					</html>`,
		[app.name, app.jsConfigUrl, app.url])

	const containerID = app.name + "-container";
	return <div style={{visibility: visible ? "visible":"collapse", display: visible ? "inline":"none"}}
					className={styles.root} id={containerID}>
		<Backdrop sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}
					 open={showBackdrop} onClick={() => setShowBackdrop(false)}>
			<BvoLoadingSpinner size={80}/>
		</Backdrop>
		<iframe
			id={app.name} title={app.name}
			/*sandbox={"allow-forms allow-same-origin allow-scripts allow-top-navigation allow-popups"}*/ //disabled see BVO-1526
			onLoad={loadAndRunSPAApp}
			srcDoc={sourceDoc}
		/>
	</div>
};

export async function executeMountFunc(func: MountFunction, app: PortalApp, targetElement: string, auth: UserManager, history: H.History) {
	console.log("Execute MountFunc", func.length, func, app, auth);
	if (func.length > 3) {
		(func as MountFunctionNG)(targetElement, app.env??{}, auth, history);
	}
	else {
		const user = await auth.getUser();
		(func as MountFunctionOld)('root', {
			token: user?.access_token ?? "", refreshToken: user?.refresh_token,
		}, history)
	}
}

export default SPAIFrameApp;
