import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	FormControlLabel,
	FormGroup,
	IconButton,
	List,
	ListItem,
	Switch,
	TextField,
	Typography
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import {useKeycloakApi, UserAccountInfo} from "../../../components/hooks/useKeycloakApi";
import {useNotifyError, useNotifySuccess} from "../../../components/NotifierMessageContainer";

export const UserAccountForm = () => {
	const [userinfo, setUserinfo] = useState<UserAccountInfo>();
	const keycloakApi = useKeycloakApi();
	const notifyError = useNotifyError();
	const notifySuccess = useNotifySuccess();

	useEffect(() => {
		keycloakApi.getUserAccountInfo()
			.then(userInfo => setUserinfo(userInfo))
			.catch(notifyError)
	}, [keycloakApi, notifyError]);

	const saveUserAccount = () => {
		userinfo && keycloakApi.updateUserAccountInfo(userinfo)
			.then(() => notifySuccess("Profil erfolgreich gespeichert!"))
			.catch(notifyError);
	}
	const changeUserInfoAttribute = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attribute: string,
		attributeIndex: number) => {
		setUserinfo(cur => {
			if (cur) {
				setAttribute(cur, attribute, attributeIndex, event.target.value);
				return {...cur}
			}
		});
	}
	const addNotifyKategorie = () => {
		setUserinfo(cur => cur && ({
				...cur,
				attributes: {
					...cur.attributes,
					"email-notify": cur.attributes["email-notify"] ? cur.attributes["email-notify"].concat([""]) : [""]
				}
			}
		))
	}
	const deleteNotifyCategory = (event: MouseEvent<HTMLButtonElement>) => {
		const index = parseInt(event.currentTarget.getAttribute("data-index") ?? "");
		if (!isNaN(index)) {
			setUserinfo(cur => cur && ({
				...cur,
				attributes: {
					...cur.attributes,
					"email-notify": (cur.attributes["email-notify"] ?? []).filter((val, idx) => idx !== index)
				}
			}))
		}
	}
	const switchNotify4All = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserinfo(cur => cur && ({
				...cur,
				attributes: {
					...cur.attributes,
					"email-notify": event.target.checked ? ["*"] : (cur.attributes["email-notify"] ?? []).filter(
						(val,) => val !== "*")
				}
			}
		))
	}

	const emailNotify = userinfo?.attributes["email-notify"];
	const emailNotify4All = (emailNotify ?? []).includes("*");

	return <Card>
		<CardHeader title={"Einstellungen"}
						subheader={`E-Mail Benachrichtigung an ${userinfo?.email} für folgende Kategorien`}/>
		<CardContent>
			<Typography variant={"h5"}>Für welche Kategorien von Neuigkeiten möchten Sie per E-Mail informiert
				werden?</Typography>
			<FormGroup>
				<FormControlLabel control={<Switch checked={emailNotify4All} onChange={switchNotify4All}/>}
										label="Alle Kategorien?"/>
			</FormGroup>
			{!emailNotify4All && <>
				<List>
					{emailNotify && emailNotify.map((val, idx) => (
						<ListItem key={idx} secondaryAction={<IconButton data-index={idx}
																						 onClick={deleteNotifyCategory}><DeleteForeverIcon/></IconButton>}>
							<TextField name={"attributes.email-notify." + idx} label="Kategorie" value={val}
										  onChange={(event) => changeUserInfoAttribute(event, "email-notify", idx)}/>
						</ListItem>
					))}
				</List>
				<Button onClick={addNotifyKategorie} startIcon={<AddBoxIcon/>}>Neue Kategorie</Button>
			</>}
		</CardContent>
		<CardActions sx={{m: 2}}>
			<Button variant={"contained"} title={"Speichern"} onClick={saveUserAccount}>Speichern</Button>
		</CardActions>
	</Card>
}

function setAttribute(obj: UserAccountInfo, attribute: string, attributeIndex: number, value: string) {
	const currAttributes = obj.attributes
	currAttributes[attribute][attributeIndex] = value
}
