import React, {createElement, SyntheticEvent, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router";
import type {PortalApp} from "../../interfaces/portal-api";
import styles from './IFrameUrlApp.module.css';
import {log} from "../../settings";
import {BvoLoadingSpinner} from "@intentus/bvo-theme-react";

const IFrameUrlApp = ({app, visible}: { app: PortalApp, visible:boolean }): JSX.Element => {
	const location = useLocation();
	const [showLoading, setShowLoading] = useState<boolean>(true)
	const [iframeUrl, setIFrameUrl] = useState<string>();
	const containerID = app.name + "-container";

	const onIFrameLoad = (event: SyntheticEvent<HTMLIFrameElement>) => {
		event.currentTarget.style.setProperty("visibility", 'visible');
		setShowLoading(false);
	}

	const ref = useRef<HTMLIFrameElement>(null);
	const iFrame = createElement('iframe', {
		ref,
		id: "app", title: app.name, src: iframeUrl, style: {visibility: "hidden"},
		//TODO: sandbox erstmal disabled, da inline-PDF sonst nicht geht
		//sandbox: "allow-forms allow-same-origin allow-scripts allow-top-navigation",
		onLoad: onIFrameLoad,
		onError: (event: Event) => alert(event)
	});

	useEffect(() => {
		if (!visible) return;
		const targetUrl = buildAppUrl(app, location.pathname, new URLSearchParams(location.search));
		log(`Change iFrame-URL for app ${app.name}`, targetUrl.toString())
		setIFrameUrl(targetUrl.toString())
	}, [app, visible, location.pathname, location.search])

	return <div style={{visibility: visible ? "visible":"collapse", display: visible ? "inline":"none"}} className={styles.root} id={containerID}>
		{iframeUrl && iFrame}
		{showLoading && <BvoLoadingSpinner/>}
	</div>
}


export function buildAppUrl(app: PortalApp, appPath: string, appParams: URLSearchParams): URL {
	const appUrl = new URL(app.url);
	appParams.forEach(((value, key) => appUrl.searchParams.append(key, value)));//append Params
	appUrl.pathname += appPath.replace(app.mountPath, "");
	return appUrl;
}

export default IFrameUrlApp;
