import {History} from "history";
import React from "react";
import type {PortalApp} from "../../interfaces/portal-api";
import {AuthContextApi} from "../authprovider/AuthContext";
import {withAuth} from "../authprovider/withAuth";
import {loadSPA, waitForDomReady} from "../hooks/SPALoader";
import {executeMountFunc} from "./SPAIFrameApp";

interface Props extends AuthContextApi {
	app: PortalApp;
	history: History;
}

interface State {
	loadedAssets: string[];
}

class WebpackNativeAppClass extends React.Component<Props, State> {

	state = {
		loadedAssets: []
	};

	componentDidMount() {
		loadSPA(this.props.app.url, document, this.props.app.jsConfigUrl)
			.then(assets => this.setState((prev) => {
				return {...prev, loadedAssets: assets as string[]}
			}))
			.then(() => waitForDomReady(window, this.props.app.mountFunc!))
			.then((mountFunc) => executeMountFunc(
				mountFunc, this.props.app, `${this.props.app.name}-container`,
				this.props.effectiveUserManager, this.props.history)
			)
			.catch((err) => {
				console.log(err);
				alert(err.message)
			})
	}

	componentWillUnmount() {
		const unmountFunc = this.props.app?.unmountFunc as keyof typeof window | undefined;

		if (unmountFunc && typeof window[unmountFunc] === 'function') {
			(window[unmountFunc] as (containerName: string) => void)(`${this.props.app.name}-container`);
			this.state.loadedAssets.forEach(file =>
				document.head.removeChild(document.getElementById(file) as HTMLElement)
			);
		}
		else {
			const errorDiv = document.getElementById('error-container');
			const rootDiv = document.getElementById('root');
			errorDiv && rootDiv && rootDiv.removeChild(errorDiv);
		}
	}

	render() {
		return <div id={`${this.props.app.name}-container`}/>
	}
}

export default withAuth(WebpackNativeAppClass);
