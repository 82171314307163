import {useCallback, useEffect, useState} from "react";
import type {NotificationSetup} from "../../interfaces/portal-api";
import {defaultFetchErrorHandler} from "../../utils/fetch-utils";
import {useAuth} from "../authprovider/useAuth";
import {useNotifyError} from "../NotifierMessageContainer";

export interface NotificationEntry {
	id: string;
	title: string;
	description: string;
	createdOn: Date;
	category: string;
	readByUser: boolean;
	recipient: UserAddress;
	sender: UserAddress;
}

export interface UserAddress {
	username: string;
	displayName?: string;
}

export interface NotificationsAPI {
	entries: NotificationEntry[];
	unreadEntries: number;
	filterUnreadOnly: boolean;
	setFilterUnreadOnly: (toggle: boolean) => void;
	markAsRead: (id: string) => Promise<void>;
	markAllAsRead: (ids: string[]) => void;
	addNotify: (entry: NotificationEntry) => Promise<void>;
}

export const useNotificationsAPI = (notifications?: NotificationSetup): NotificationsAPI => {

	const auth = useAuth();
	const notifyError = useNotifyError();
	const [filterUnreadOnly, setFilterUnreadOnly] = useState<boolean>(true);
	const [entries, setEntries] = useState<NotificationEntry[]>([]);
	const unreadEntries = entries.filter(e => !e.readByUser).length;

	const fetchEntries = useCallback(() => {
		return notifications?.notificationFetchUrl ?
			fetch(notifications.notificationFetchUrl.replaceAll("@UNREAD_ONLY@", String(filterUnreadOnly)),
				{
					headers: {
						"Accept": "application/json",
						"Authorization": "Bearer " + auth.self?.access_token
					}
				})
				.then(defaultFetchErrorHandler)
				.then(res => res.json())
				.then(json => toResult(json.content))
				.catch(notifyError)
			:
			Promise.reject("Feature not configured! (notificationFetchUrl)");
	}, [notifications?.notificationFetchUrl, filterUnreadOnly, auth.self?.access_token, notifyError]);

	const addNotify = useCallback((entry: NotificationEntry) => {
		return notifications?.notificationAddUrl ?
			fetch(notifications.notificationAddUrl, {
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + auth.self?.access_token
				},
				body: JSON.stringify(entry)
			}).then(defaultFetchErrorHandler)
				.then(() => {
					return;
				})
				.catch(notifyError)
			:
			Promise.reject("Feature not configured! (notificationAddUrl)");
	}, [notifications?.notificationAddUrl, auth.self?.access_token, notifyError])

	const markAsRead = useCallback((id: string) => {
		return notifications?.notificationMarkAsReadUrl ?
			fetch(notifications.notificationMarkAsReadUrl.replaceAll("@ID@", id), {
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Authorization": "Bearer " + auth.self?.access_token
				}
			}).then(defaultFetchErrorHandler)
				.then(fetchEntries)
				.then(res => setEntries(res))
				.catch(notifyError)
			:
			Promise.reject("Feature not configured! (notificationMarkAsReadUrl)");
	}, [notifications?.notificationMarkAsReadUrl, auth.self?.access_token, fetchEntries, notifyError]);


	const markAllAsRead = useCallback((ids: string[]) => {
		return Promise.allSettled(ids.map((id: string) => markAsRead(id)));
	}, [markAsRead]);

	// instant load and auto-reload every minute
	useEffect(() => {
		let mounted = true;
		fetchEntries().then(res => mounted && setEntries(res)).catch(console.error)
		const interval = setInterval(() => {
			fetchEntries().then(res => mounted && setEntries(res)).catch(console.error);
		}, 60 * 1000);

		return () => {
			mounted = false;
			clearInterval(interval);
		}
	}, [fetchEntries, filterUnreadOnly]);


	return {
		entries,
		unreadEntries,
		filterUnreadOnly,
		setFilterUnreadOnly,
		markAsRead,
		markAllAsRead,
		addNotify
	}
}

function toResult(json: NotificationEntry): NotificationEntry[] {
	if (!Array.isArray(json)) {
		return [jsonToNotify(json)]
	}
	return json.map((newsEntityJson: NotificationEntry) => {
		return jsonToNotify(newsEntityJson)
	})
}

export function jsonToNotify(json: NotificationEntry): NotificationEntry {
	return {
		...json,
		createdOn: new Date(json.createdOn)
	}
}
