import * as H from "history";
import {createBrowserHistory} from "history";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {Router} from "react-router-dom";

export const portalHistory:H.History = createBrowserHistory();

type Props = {
	history?: H.History;
}

export const PortalNavigationRouter:React.FC<PropsWithChildren<Props>> = ({history = portalHistory, children}) => {
	const [portalLoc, setPortalLoc] = useState(history.location);

	useEffect(() => {
		const unlisten = history.listen(update => {
			setPortalLoc(update.location);
		})
		return () => unlisten();
	}, [history])

	return <Router navigator={history} location={portalLoc}>
		{children}
	</Router>
}

