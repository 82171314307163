import React from "react";
import {AuthContext, AuthContextApi} from "./AuthContext";

export const useAuth = (): AuthContextApi => {
	const context = React.useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within a <OidcAuthProvider> component.");
	}
	return context;
}
