export const decodeToken = (str: string) => {
	str = str.split('.')[1];
	str = str.replace(/-/g, '+');
	str = str.replace(/_/g, '/');
	switch (str.length % 4) {
		case 0:
			break;
		case 2:
			str += '==';
			break;
		case 3:
			str += '=';
			break;
		default:
			throw new Error('Invalid token');
	}
	str = decodeURIComponent(escape(atob(str)));
	return JSON.parse(str);
}

export const hasAuthParams = (location = window.location): boolean => {
	// response_mode: query
	let searchParams = new URLSearchParams(location.search);
	if ((searchParams.get("code") || searchParams.get("error")) &&
		searchParams.get("state")) {
		return true;
	}

	// response_mode: fragment
	searchParams = new URLSearchParams(location.hash.replace("#", "?"));
	return !!((searchParams.get("code") || searchParams.get("error")) &&
		searchParams.get("state"));
};
const normalizeErrorFn = (fallbackMessage: string) => (error: unknown): Error => {
	if (error instanceof Error) {
		return error;
	}
	return new Error(fallbackMessage);
};
export const signinError = normalizeErrorFn("Sign-in failed");
