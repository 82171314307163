import {themeBarmenia, useLocalizedThemeByName, useLocalizedThemeSelector} from "@intentus/bvo-theme-react";
import {CssBaseline, ThemeProvider} from '@mui/material';
import React, {useEffect} from 'react';
import Favicon from "react-favicon";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import {useAuth} from "./components/authprovider/useAuth";
import {hasAuthParams} from "./components/authprovider/utils";
import {AppComponent} from "./components/frontends/AppComponent";
import {appEquals, usePortalMenu} from "./components/hooks/usePortalMenu";
import {useTitle} from "./components/hooks/useTitle";
import {Logout} from "./components/Logout";
import {NotifierMessageContainer} from "./components/NotifierMessageContainer";
import PortalLayout from "./components/PortalLayout";
import {PortalRedirect} from "./components/PortalRedirect";
import {NotificationsPage} from "./pages/Notifications/NotificationsPage";
import {ProfilePage} from "./pages/ProfilePage/ProfilePage";
import {WechselAppPage} from "./pages/WechselApp/WechselAppPage";
import {settings} from "./settings";

function App() {
	const auth = useAuth();
	const portalMenuAPI = usePortalMenu();
	const theme = useLookupTheme();
	useTitle(portalMenuAPI.currentApp?.name ?? "Dashboard")
	useEffect(() => {
		if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading) {
			auth.login();//autologin
		}
	}, [auth]);
	return <>
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Favicon url={theme.generalOptions?.favicon??''} />
			<PortalLayout menuAPI={portalMenuAPI}>
				{auth.isAuthenticated && portalMenuAPI.menu.apps.length > 0 &&
					<>
						{/* RENDER OPEN APPS */}
						{portalMenuAPI.openApps.map(app =>
							<AppComponent key={app.mountPath} app={app} history={portalMenuAPI.getAppHistory(app)}
											  visible={appEquals(app, portalMenuAPI.currentApp)}/>)
						}
							<Routes>
								<Route path="/" element={<Navigate to={portalMenuAPI.menu.dashboardPath} replace />}/>
								<Route path="/welcome" element={<div><div style={{padding: '1.5rem'}}>Welcome to the NextGen Portal</div></div>}/>
								<Route path={"/wechsel"} element={<WechselAppPage/>}/>
								<Route path={"/notifications"} element={<NotificationsPage menu={portalMenuAPI.menu}/>}/>
								<Route path={"/redirect"} element={<PortalRedirect/>}/>
								<Route path={"/profile"} element={<ProfilePage/>}/>
								<Route path={"/logout"} element={<Logout/>}/>
								<Route path={"*"} element={<></>}/>{/* dummy to avoid warning for all (open)app-routes*/}
							</Routes>
					</>
				}
				<NotifierMessageContainer/>
			</PortalLayout>
		</ThemeProvider>
	</>
}

function useLookupTheme() {
	const themeName = settings.portalTheme;
	const localizedThemeByName = useLocalizedThemeByName(themeName??"", themeBarmenia);
	const localizedThemeSelector = useLocalizedThemeSelector(themeBarmenia);
	if (themeName) {
		document.cookie = "bvo_theme=" + themeName;
		return localizedThemeByName;
	}
	else {
		return localizedThemeSelector;
	}
}

export default App;
