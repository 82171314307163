import type {PortalSettings} from "./vite-env";

const lookupEnvValue = (name:string):string|undefined => {
	const val:string|undefined = (window as never)["env"]?.[name] as string;
	if (val && !val.startsWith("@")) return val;
	return import.meta.env["VITE_"+name];
}

export const settings:PortalSettings = {
	authorityUrl: lookupEnvValue("AUTHORITY_URL") ?? "http://keycloak.bvo.local/auth/realms/Barmenia-Develop",
	authorityUserSearchUrl: lookupEnvValue("AUTHORITY_SEARCH_URL") ?? "http://keycloak.bvo.local/auth/admin/realms/Barmenia-Develop/users?",
	authorityExtendedUserSearchUrl: lookupEnvValue("AUTHORITY_EXSEARCH_URL") ?? "http://keycloak.bvo.local/auth/realms/Barmenia-Develop/OT-Extensions?",
	clientId: lookupEnvValue("CLIENT_ID") ?? "BVO_WebpartsReact",
	portalTheme: lookupEnvValue("PORTAL_THEME"),
	portal_config_url: lookupEnvValue("PORTAL_CONFIG_URL") ?? "/apps.json",
	enableDebugLogger: (lookupEnvValue("DEBUG_LOGGER") ?? "true") === "true"
}

export const log = (...args: unknown[]) => {
	if (settings.enableDebugLogger)
		console.log(...args);
}
