import React from "react";
import {PortalMenuAPI} from "../hooks/usePortalMenu";
import {MenuMainNavigation} from "./MenuMainNavigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Box, Drawer, List, ListItemButton, ListItemIcon, styled} from "@mui/material";
import {BvoTheme} from "@intentus/bvo-theme-react";

export const Sidebar = ({menuAPI, toggleDrawer, drawerWidth, padding}:
	{ menuAPI: PortalMenuAPI, toggleDrawer: (setOpen?:boolean) => void, drawerWidth?: string, padding?: string}): JSX.Element => {

	return <Drawer variant="permanent" sx={{width: drawerWidth, display:'flex', height: '100%'}}>
			<MenuIconButtonBox />
			<NavList dense={true} sx={{padding: padding??'inherit'}}>
				<MenuMainNavigation
					menuAPI={menuAPI}
					toggleDrawer={toggleDrawer}/>
			</NavList>
			{menuAPI.menu.supportLink && <BottomLink>
				<ListItemButton sx={{pb: '20px', pt: '20px'}}>
					<CategoryIcon>
						<FontAwesomeIcon icon={faEnvelope} onClick={() => toggleDrawer()} size={"sm"}/>
					</CategoryIcon>
					<StyledLink href={menuAPI.menu.supportLink} rel="noreferrer" target="_blank">
						Mail an Support
					</StyledLink>
				</ListItemButton>
			</BottomLink>}
		</Drawer>
}

const MenuIconButtonBox = styled(Box)(({theme}:{theme: BvoTheme}) => ({
	display: 'flex',
	height: theme.toolbarProperties.height,
	flexDirection: 'row',
	flexGrow: 0,
	flexShrink: 0,
	justifyContent: 'flex-end',
	alignItems: 'center'
}));

const BottomLink = styled(List)(({theme}:{theme: BvoTheme}) => ({
	borderTop: '1px solid rgba(0, 0, 0, 0.13)',
	padding: '0 0 0 0',
	lineHeight: '0.3em',
	display: 'flex',
	alignItems: 'center',
	color: theme.drawerProperties.color,
	textDecoration: 'none',
	width: '100%'
}));

const StyledLink = styled('a')(({theme}:{theme: BvoTheme}) => ({
	color: theme.drawerProperties.color,
	textDecoration: 'none'
}));


const NavList = styled(List)(() => ({
	listStyle: 'none',
	margin: '10px 0 0 0',
	padding: 0,
	boxSizing: 'unset',
	overflowY: 'auto',
	overflowX: 'hidden',
	flexGrow:1
}));

const CategoryIcon = styled(ListItemIcon)(() => ({
	color: 'inherit',
	minWidth: '40px',
	fontSize:'20px'
}));
