import {User} from "oidc-client-ts";

import {decodeToken} from "./utils";

//TODO: Die aktuelle Implementierung ist nur für Keycloak IDPs, für BSF/... braucht man eine andere.
export function extractRoles(user?:User|null):string[] {
	if (user) {
		const jwt = decodeToken(user.access_token);
		const result = new Array<string>();
		jwt?.resource_access && Object.entries(jwt?.resource_access as object).forEach(([client,clientData]) => {
			if (clientData["roles"]) {
				result.push(...clientData["roles"].map((role:string) => `${role}@${client}`));
			}
		})
		const realmRoles = jwt?.realm_access ? ((jwt?.realm_access?.roles) as Array<string>).map(role => role + '@REALM') ?? [] : [];
		result.push(...realmRoles)
		return result;
	}
	return [];
}
