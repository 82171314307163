import {useMediaQuery, useTheme} from "@mui/material";

export const useScreenType = (): string => {

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const tablet = useMediaQuery(theme.breakpoints.between('sm','md'));

	if (mobile) return 'mobile';
	if (tablet) return 'tablet';
	return 'desktop';

}
