import {useMemo} from "react";
import type {KeycloakUser} from "../../interfaces/portal-api";
import {settings} from "../../settings";
import {defaultResponseErrorCheck} from "../../utils/fetch-utils";
import {useAuth} from "../authprovider/useAuth";

interface KeycloakSearchApi {
	search: (query: Query | string, first?: number, max?: number) => Promise<KeycloakUser[]>
	getUserAccountInfo: () => Promise<UserAccountInfo>
	updateUserAccountInfo: (accountInfo: UserAccountInfo) => Promise<void>;
}

type QueryPart = searchField | searchLiteral | searchOp | sub | Query;
type searchField = { field: string };
type searchLiteral = { literal: string };
type searchOp =
	"equal"
	| "notEqual"
	| "contains"
	| "startsWith"
	| "lessThen"
	| "lessEqual"
	| "greaterThen"
	| "greaterEqual"
	| "isPresent"
	| "not"
	| "and"
	| "or";
type sub = { term: Query }
export type Query = { tokens: QueryPart[] }

export interface UserAccountInfo {
	id: string;
	username: string;
	email?: string;
	emailVerified?: boolean;
	firstName?: string;
	lastName?: string;
	attributes: { [attr: string]: string[] };
	//TODO:beschreibt bestimmte Attribute oben näher mit Details bzgl. required/validators/read-only....wir brauchen das aktuell nicht...
	userProfileMetadata?: unknown;
}

export function buildQuery(part: QueryPart): object {
	const value = valueOf(part);
	if (value instanceof Array) {
		return value;
	}
	return {
		type: typeOf(part),
		...value
	}
}

function typeOf(part: QueryPart): string {
	if ((part as searchField).field) {
		return "searchField";
	}
	if ((part as searchLiteral).literal) {
		return "searchLiteral";
	}
	if ((part as sub).term) {
		return "sub";
	}
	if ((part as Query).tokens) {
		return "query";
	}
	return "op";
}

function valueOf(part: QueryPart): object {
	if ((part as searchField).field) {
		return {fieldName: (part as searchField).field}
	}
	if ((part as searchLiteral).literal) {
		return {value: (part as searchLiteral).literal}
	}
	if ((part as sub).term) {
		return {term: valueOf((part as sub).term)}
	}
	if ((part as Query).tokens) {
		return {tokens: (part as Query).tokens.map(buildQuery)}
	}
	return ["op", (part as searchOp) as string];
}


export const useKeycloakApi = (): KeycloakSearchApi => {
	const auth = useAuth();
	return useMemo(() => {
		return {
			search: (query: Query | string, first?: number, max?: number) => {
				let url;
				let body;
				const params = new URLSearchParams();
				params.append("first", "" + (first ?? "0"));
				params.append("max", "" + (max ?? "10"));
				params.append("briefRepresentation", "true");
				if (typeof query === "string") {
					url = settings.authorityUserSearchUrl;
					params.append("search", query);
				}
				else {
					url = settings.authorityExtendedUserSearchUrl;
					body = JSON.stringify(buildQuery(query));
				}
				return fetch(url + params.toString(), {
					method: typeof query === "string" ? "GET" : "POST",
					body: body,
					headers: {
						"Authorization": "Bearer " + auth.self?.access_token,
						"Accept": "application/json",
						"Content-Type": "application/json",
						"Origin": window.location.origin,
					}
				})
					.then(defaultResponseErrorCheck("Fehler bei Nutzersuche im Keycloak! Details:"))
					.then(response => response.json())
			},
			getUserAccountInfo: () => {
				//TODO: Endpunkt ermitteln, ist bei Keycloak die API von /account identisch zu auth.settings.metadata?.userinfo_endpoint?
				const accountInfoUrl = `${settings.authorityUrl}/account`
				return fetch(accountInfoUrl, {
					method: "GET",
					headers: {
						Authorization: "Bearer " + auth.self?.access_token,
						Accept: "application/json"
					}
				}).then(defaultResponseErrorCheck("Fehler beim Laden des Profils! Details:")).then(res => res.json())
			},
			updateUserAccountInfo: (accountInfo: UserAccountInfo) => {
				const accountInfoUrl = `${settings.authorityUrl}/account`
				accountInfo["userProfileMetadata"] = undefined;//WAF-Protection in Wuppertal
				return fetch(accountInfoUrl, {
					method: "POST",
					headers: {
						Authorization: "Bearer " + auth.self?.access_token,
						Accept: "application/json", "Content-Type": "application/json",
					},
					body: JSON.stringify(accountInfo)
				}).then(defaultResponseErrorCheck("Fehler beim Speichern des Profils! Details:"))
					.then(() => {
						return
					})
			}
		}
	}, [auth.self?.access_token]);
}
