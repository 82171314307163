import {Navigate, useLocation} from "react-router";
import {ErrorBoundary} from "./ErrorBoundary";

interface Props {
	toUrl?: string;
	target?: "_blank"|"_self";
}

export const PortalRedirect = ({toUrl, target}:Props) => {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const to = toUrl??urlParams.get("to") as string;
	console.log("Redirect to " + to);
	if (to.startsWith("http")) {
		if (target) {
			window.open(to, target);
		}
		else {
			window.location.replace(to);
		}
		return <></>
	}
	return <ErrorBoundary><Navigate to={to} replace={true}/></ErrorBoundary>
}