import React from "react";
import * as serviceWorker from "./serviceWorkerRegistration";

interface ServiceWorkerControl {
	isPushAvailable: () => Promise<boolean>;
	isSubscribedToPush: () => Promise<boolean>;
	pushSubscribe: (url: string, pubKey: string, accessToken: string) => Promise<Response>;
	pushUnsubscribe: (url: string, accessToken: string) => Promise<boolean>;
}

const ServiceWorkerContext = React.createContext<ServiceWorkerControl | undefined>(undefined);

function backendSubscribeOrUnsubscribe(url: string, subscription: PushSubscription, accessToken: string) {
	return fetch(url, {
		method: "POST",
		headers: {
			"Authorization": "Bearer " + accessToken,
			"Content-Type": "application/json"
		},
		body: JSON.stringify(subscription)
	})
}

function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
const ServiceWorkerProvider = ({children} : { children: React.ReactNode }) => {
	const value = React.useMemo(
		() => ({
			isPushAvailable: () => {
				if ('serviceWorker' in navigator) {
					return navigator.serviceWorker.ready.then(reg => reg.pushManager !== undefined);
				}
				return Promise.reject("ServiceWorker is unsupported");
			},
			isSubscribedToPush: () => {
				if ('serviceWorker' in navigator) {
					return navigator.serviceWorker.ready
						.then(reg => reg.pushManager.getSubscription())
						.then(sub => sub != null)
						.catch(() => false)
				}
				return Promise.reject("ServiceWorker is unsupported");
			},
			pushSubscribe: (url: string, pubKey: string, accessToken: string) => {
				if ('serviceWorker' in navigator) {
					return navigator.serviceWorker.ready.then(reg => {
						const pubKeyBin = urlBase64ToUint8Array(pubKey);
						return reg.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: pubKeyBin})
					})
						.then(sub => backendSubscribeOrUnsubscribe(url + "/subscribe", sub, accessToken))
				}
				return Promise.reject("ServiceWorker is unsupported")
			},
			pushUnsubscribe: (url: string, accessToken: string) => {
				if ('serviceWorker' in navigator) {
					return navigator.serviceWorker.ready
						.then(sw => sw.pushManager.getSubscription())
						.then(sub => sub ?
							backendSubscribeOrUnsubscribe(url + "/unsubscribe", sub, accessToken)
								.then(() => sub.unsubscribe())
							:
							Promise.resolve(false))
				}
				return Promise.reject("ServiceWorker is unsupported")
			}
		}), []);

	// Once on component mounted subscribe to Update and Success events in
	// CRA's service worker wrapper
	React.useEffect(() => {
		serviceWorker.register({
			onUpdate: registration => {
				if (Notification.permission === 'granted') {
					registration.showNotification("Es ist eine neue BVO-Portal Version verfügbar!", {
						icon: 'img/apple-touch-icon.png',
						body: "Das Update wird automatisch ausgelöst (inkl. Reload)!"
					});
				}
				//Aktuell: Wir aktivieren den neuen SW automatisch (postMessage(SKIP_WAITING)) und führen das Reload aus.
				// Das kännte auch "ondemand" erfolgen (User-Click im Menu) wenn dem User das Reload passend erscheint!
				registration.waiting!.addEventListener("statechange", event => {
					if ((event.target as ServiceWorker)?.state === "activated") {
						window.location.reload()
					}
				});
				registration.waiting!.postMessage({type: "SKIP_WAITING"});
			},
			onSuccess: () => {
				console.debug("ServiceWorker successfully installed");
			}
		});
	}, []);

	return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
}

function useServiceWorker() {
	const context = React.useContext(ServiceWorkerContext);
	if (!context) {
		throw new Error("useServiceWorker must be used within a ServiceWorkerProvider");
	}
	return context;
}

export {ServiceWorkerProvider, useServiceWorker};