//load js/css into target document and return Promise for all loaded css/js style/script urls
import * as H from "history";
import {UserManager} from "oidc-client-ts";
import type {ManifestType} from "../../interfaces/portal-api";
import {log} from "../../settings";
import type {KeycloakTokens} from "../../vite-env";
import {ManifestLoader} from "./ManifestLoader";

export type MountFunctionOld = (targetID: string, tokens: KeycloakTokens, history: H.History) => void
export type MountFunctionNG = (targetID: string, env: Record<string, unknown>, userManager: UserManager, history: H.History) => void;
export type MountFunction = MountFunctionOld | MountFunctionNG;

interface MountPromise {
	resolve: (value: (MountFunction | PromiseLike<MountFunction>)) => void;
	reject: (value: (Error | PromiseLike<MountFunction>)) => void;
}

/**
 * Lade eine mit Webpack|Vite gebündelte App in ein Document. D.h. alle Javascript/CSS Files aus dem Manifest
 * werden in entsprechende script/style Element im Zieldokument eingebunden.
 * Die ConfigUrl wird zusätzlich geladen. Achtung: keine Garantie das diese "rechtzeitig" geladen ist, falls
 * Werte daraus beim Laden der anderen Scripte bereits verwendet werden.
 *
 * @param baseUrl
 * @param document
 * @param configUrl
 * @param manifestType
 */
export function loadSPA(baseUrl: string, document: Document, configUrl?: string, manifestType?:ManifestType) {
	return ManifestLoader(baseUrl, manifestType??"webpack")
		.then(assets => {
			const js = [...(configUrl ? [configUrl] : []), ...assets.js];
			const jsLoader = js.map(url => loadScript(document, url, manifestType === "vite", url !== configUrl))
			const cssLoader = assets.css.map(url => loadStyles(document, url));
			return Promise.all([...jsLoader, ...cssLoader]);
		})
}

const loadScript = (document: Document, url: string, esModule: boolean, allowAsync: boolean): Promise<string> => {
	return new Promise(((resolve, reject) => {
		const script: HTMLScriptElement = document.createElement('script');
		script.id = url;
		script.src = url;
		script.async = allowAsync;
		if (esModule) {
			//allow ES6/ES2015 modules to work (i.e. vite-apps don't encapsulate their builds through esbuild)
			script.type = "module";
		}
		if (!document.getElementById(url)) {
			log(`Load App-Javascript-Asset ${url}`, script)
			script.addEventListener("load", () => resolve(url))
			script.addEventListener("error", (err) =>
				reject(new Error("Fehler beim Laden der URL " + url + "! " + err.message)))
			document.head.appendChild(script);
		}
		else {
			log(`App-Javascript-Asset ${url} already loaded`)
			resolve(url);
		}
	}))
}

const loadStyles = (document: Document, url: string): Promise<string> => {
	return new Promise(((resolve, reject) => {
		const styleLink = document.createElement('link');
		styleLink.id = url;
		styleLink.href = url;
		styleLink.rel = 'stylesheet';
		if (!document.getElementById(url)) {
			log(`Loading App-CSS-Asset `, styleLink)
			styleLink.addEventListener("load", () => resolve(url))
			styleLink.addEventListener("error", (err) =>
				reject(new Error("Fehler beim Laden der URL " + url + "! " + err.message)))
			document.head.appendChild(styleLink);
		}
		else {
			resolve(url);
		}
	}))
}


export function waitForDomReady(window: Window, functionName: string): Promise<MountFunction> {
	const p: MountPromise = {
		resolve: () => {/*empty default*/},
		reject: () => {/*empty default*/}
	}
	const promise = new Promise<MountFunction>((resolve, reject) => {
		p.resolve = resolve;
		p.reject = reject;
	})
	let timerId: number | undefined = undefined;
	let repeatCounter = 0;
	const checkReadyState = function () {
		if (timerId) {
			window.clearTimeout(timerId);
			timerId = undefined;
		}
		if ((window as never)[functionName]) {
			p.resolve((window as never)[functionName] as MountFunction);
		}
		else {
			repeatCounter++;
			if (repeatCounter < 50) {
				timerId = window.setTimeout(checkReadyState, 100)
			}
			else {
				p.reject(new Error(`MountFunction ${functionName} not found in Target-Window after 50 retries.`));
			}
		}
	}
	checkReadyState();
	return promise;
}
