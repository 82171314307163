import {AuthContextApi} from "./AuthContext";
import {useAuth} from "./useAuth";

export function withAuth<P>(
	Component: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof AuthContextApi>> {
	const displayName = `withAuth(${Component.displayName || Component.name})`;
	const C: React.FC<Omit<P, keyof AuthContextApi>> = (props) => {
		const auth = useAuth();

		return <Component {...(props as P)} auth={auth} />;
	};

	C.displayName = displayName;

	return C;
}
