import {BvoAppBar, useBvoAutocomplete} from "@intentus/bvo-theme-react";
import {
	Button, Card, CardActions, CardContent, CardHeader,
	Container,
	FormControlLabel,
	Grid,
	List,
	ListItem, Paper,
	Stack,
	Switch,
	TextField,
	Typography
} from "@mui/material";
import React from "react";
import {NotificationEntry, useNotificationsAPI} from "../../components/hooks/useNotificationsAPI";
import {NotificationListItem} from "../../components/toolbar/ToolbarNotifications";
import {FormikProps, useFormik} from "formik";
import type {KeycloakUser, PortalMenu} from "../../interfaces/portal-api";
import {useNotifyError} from "../../components/NotifierMessageContainer";
import {useKeycloakApi} from "../../components/hooks/useKeycloakApi";

interface FormValues {
	empfaenger: KeycloakUser;
	absender: string;
	title: string;
	description: string;
}

export const NotificationsPage = ({menu}: { menu: PortalMenu }) => {
	const notifyAPI = useNotificationsAPI(menu.notifications);
	const keycloakApi = useKeycloakApi();
	const errorHandler = useNotifyError();
	const formik: FormikProps<FormValues> = useFormik<FormValues>({
		initialValues: {
			empfaenger: {username: '', firstName: '', lastName: ''} as KeycloakUser,
			absender: '',
			title: '',
			description: ''
		},
		validateOnChange: false,
		onReset: () => autocomplete.control.clear(),
		onSubmit: (values) => {
			notifyAPI.addNotify({
				id: "",
				title: values.title,
				description: values.description,
				createdOn: new Date(),
				recipient: {
					username: values.empfaenger.username,
					displayName: values.empfaenger.firstName + ' ' + values.empfaenger.lastName
				},
				sender: {username: "", displayName: values.absender},
				category: "Benachrichtigung",
				readByUser: false
			})
				.then(res => {
					autocomplete.control.clear();
					formik.resetForm();
					return res;
				})
				.catch(err => console.error(err))
		},
	});
	const markAsRead = (entry: NotificationEntry) => notifyAPI.markAsRead(entry.id);
	const userSearch = (term: string) => keycloakApi.search(term).catch(errorHandler);

	const onUserChange = (event: React.SyntheticEvent, value: KeycloakUser | null) => {
		formik.setFieldValue("empfaenger", value);
	}
	const autocomplete = useBvoAutocomplete<KeycloakUser>({
		searchSuggestions: userSearch,
		autocompleteProps: {
			isOptionEqualToValue: (option, value) => option?.username === value?.username,
			multiple: false,
			value: formik.values.empfaenger,
			getOptionLabel: user => user.firstName + " " + user.lastName + " (" + user.username + ")",
			onChange: (e, value) => onUserChange(e, value ?? null),
		},
		textFieldProps: {
			placeholder: "Empfänger suchen...",
			label: "Empfänger"
		}
	})


	return <>
		<BvoAppBar barContentLeft={<Typography variant="h2" noWrap>Benachrichtigungen</Typography>}/>

		<Container sx={{p: 3}}>
			<form onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader title={"Neue Benachrichtigung"}/>
					<CardContent>
						<Grid container direction="row" spacing={3} marginBottom={4}>
							<Grid item xs={12} sm={9} md={6} lg={6}>
								{autocomplete.ui}
							</Grid>
							<Grid item xs={12} sm={9} md={6} lg={6}>
								<TextField label="Absender" name="absender" value={formik.values.absender}
											  onChange={formik.handleChange} onBlur={formik.handleBlur}/>
							</Grid>
							<Grid item xs={12} sm={9} md={6} lg={6}>
								<TextField label="Überschrift" name="title" value={formik.values.title}
											  onChange={formik.handleChange} onBlur={formik.handleBlur}/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField label="Nachricht (HTML)" name="description" multiline rows={4}
											  value={formik.values.description}
											  onChange={formik.handleChange} onBlur={formik.handleBlur}/>
							</Grid>
							<Grid item xs={2}>

							</Grid>
							<Grid item xs={2}>

							</Grid>
						</Grid>
					</CardContent>
					<CardActions sx={{m: 2}}>
						<Button color="primary" variant="contained" type="submit">Abschicken</Button>
						<Button color="primary" variant="contained" type="reset"
								  onClick={() => formik.resetForm()}>Leeren</Button>
					</CardActions>
				</Card>
			</form>
			<Typography variant={"h4"} mt={4}>Ihre Benachrichtigungen</Typography>
			<Stack direction={"row"} justifyContent={"space-between"} sx={{ml: 3, mr: 3}}>
				<FormControlLabel
					control={<Switch checked={notifyAPI.filterUnreadOnly}
										  onChange={() => notifyAPI.setFilterUnreadOnly(!notifyAPI.filterUnreadOnly)}
					/>}
					label="Nur ungelesene"
					labelPlacement="end"
				/>
			</Stack>
			{/* TODO: besser eine Virtualized List nutzen? */}
			{notifyAPI.entries.length > 0 && <List>
				<Typography variant={'h6'}>{notifyAPI.entries[0].createdOn.toLocaleDateString()}</Typography>
				{notifyAPI.entries.map((entry, nr, arr) =>
					<React.Fragment key={nr}>
						{nr > 0 && arr[nr - 1].createdOn.getDay() !== entry.createdOn.getDay() ?
							<Typography variant={'h6'}>{entry.createdOn.toLocaleDateString()}</Typography>
							:
							<></>
						}
						<Paper sx={{mb: '1px'}}><List sx={{width: '100%'}}>
							<ListItem alignItems="flex-start" key={nr}>
								<NotificationListItem entry={entry} markAsRead={markAsRead}/>
							</ListItem>
						</List></Paper>
					</React.Fragment>
				)}
			</List>}
		</Container>
	</>
}
