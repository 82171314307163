import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp, library} from "@fortawesome/fontawesome-svg-core";
import {PortalMenuAPI} from "../hooks/usePortalMenu";
import {MenuItem} from "./MenuItem";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useAtom} from "jotai";
import {drawerExpandedAtom} from "../../contexts/atoms";
import type {PortalCategories} from "../../interfaces/portal-api";
import {BvoTheme} from "@intentus/bvo-theme-react";

library.add(fas);
library.add(far);

interface Props {
	menuAPI: PortalMenuAPI,
	toggleDrawer: (setOpen?: boolean) => void
}

export const CategoryButton = styled(ListItemButton)(({theme}: { theme: BvoTheme }) => ({
	marginBottom: theme.drawerProperties.categoryMarginVertical??'0',
	paddingTop: theme.drawerProperties.categoryPaddingVertical??'4px',
	paddingBottom: theme.drawerProperties.categoryPaddingVertical??'4px',
	borderRadius: theme.drawerProperties.activeBorderRadius ?? 0,
	'.MuiListItemText-root': {
		marginTop: theme.drawerProperties.categoryTextMarginVertical??'4px',
		marginBottom: theme.drawerProperties.categoryTextMarginVertical??'4px',
	},
	'&:hover': {
		backgroundColor: theme.drawerProperties.activeBackgroundColor ? theme.drawerProperties.activeBackgroundColor
			+ ' !important' : (theme.drawerProperties.hoverBackgroundColor ? theme.drawerProperties.hoverBackgroundColor
			+ ' !important' : 'inherit'),
		color: theme.drawerProperties.hoverColor ?? 'inherit',
	},
}));

export const MenuCategoriesList = ({menuAPI, toggleDrawer}: Props): React.ReactElement => {

	const [expanded] = useAtom(drawerExpandedAtom);
	const [activeMenuCategories, setActiveMenuCategories] = useState<string[]>([]);

	const toggleMenuCategory = (categoryName: string) => {
		setActiveMenuCategories((prev) => activeMenuCategories.includes(categoryName) ? activeMenuCategories.filter(
			name => name != categoryName) : [...prev, categoryName]);
	};

	const renderMenuCategories = (categories: PortalCategories, path: string) => {
		return Object.entries(categories).map(([categoryName, categoryProps], i) => {
			const {icon, categories: subCategories} = categoryProps;

			const apps = menuAPI.menu.apps
				.filter(app => app.category === (categoryName))
				.map((app, j) => <MenuItem key={`${i}_${j}`} menuAPI={menuAPI} app={app} toggleDrawer={toggleDrawer}/>);
			const actualPath = path + "/" + categoryName;
			return (<React.Fragment key={i}>
				{apps.length > 0 &&
						<CategoryButton onClick={() => {
							toggleMenuCategory(actualPath);
							toggleDrawer(true)
						}}> {icon &&<CategoryIcon>
							<FontAwesomeIcon icon={icon as IconProp} color={'inherit'} size={"sm"}/>
						</CategoryIcon>}
							<ListItemText primary={categoryName} disableTypography/>
							{activeMenuCategories.includes(categoryName) ? <ExpandLess/> : <ExpandMore/>}
						</CategoryButton>
				}
				{expanded && activeMenuCategories.includes(actualPath) &&
						<Collapse in={true} unmountOnExit>
							{subCategories && <Box sx={{pl:'40px'}}>{renderMenuCategories(subCategories, actualPath)}</Box>}
							<nav>
								<List>
									{apps}
								</List>
							</nav>
						</Collapse>}
			</React.Fragment>);
		});
	};
	return <>{
		renderMenuCategories(menuAPI.menu.categories, '')
	}</>;
};

const CategoryIcon = styled(ListItemIcon)(() => ({
	color: 'inherit',
	minWidth: '40px',
	fontSize: '20px'
}));

