import {BvoAppBar} from "@intentus/bvo-theme-react";
import {CheckCircle, CircleOutlined, Clear, Search} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Container,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
	Typography
} from "@mui/material";
import React, {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import {useAuth} from "../../components/authprovider/useAuth";
import {useKeycloakApi} from "../../components/hooks/useKeycloakApi";
import {usePortalMenu} from "../../components/hooks/usePortalMenu";
import {useNotifyError} from "../../components/NotifierMessageContainer";
import type {KeycloakUser} from "../../interfaces/portal-api";

//TODO: split with Container-Pattern to dumb-UI / Logic
export const WechselAppPage = () => {
	const [ inputSearchValue, setInputSearchValue ] = useState<string>("");
	const [ suggestions, setSuggestions ] = useState<KeycloakUser[]>([]);
	const [ requestedSubject, setRequestedSubject ] = useState<KeycloakUser>();
	const notifyError = useNotifyError();

	const keycloakApi = useKeycloakApi();
	const portalMenu = usePortalMenu();
	const auth = useAuth();

	const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => setInputSearchValue(event.target.value);
	const selectLogin = (event: MouseEvent<HTMLLIElement>) => {
		const index = event.currentTarget.value;
		setRequestedSubject(suggestions[index]);
	}

	const changeLogin = () => {
		auth.impersonate(requestedSubject!.username)
			.then(portalMenu.closeAllApps)
			.catch(notifyError);
	}

	useEffect(() => {
		//const query: Query = {tokens: [{field: "username"}, "startsWith", {literal: changeSearchValue}]};
		const query = inputSearchValue;
		keycloakApi.search(query)
			.then(resp => setSuggestions(resp))
			.catch(notifyError);
	}, [keycloakApi, inputSearchValue, setSuggestions, notifyError])

	return <>
		<BvoAppBar barContentLeft={<Typography variant="h2" noWrap>Nutzer wechseln</Typography>}/>
		<Box sx={{pr: 3, pl: 3}}>
			<TextField value={inputSearchValue} onChange={changeSearchValue}
						  placeholder="Suchen…" variant={"outlined"} size={"small"} autoFocus
						  sx={{width: {md:  '400px',xs:  '100%'}}}
						  InputProps={{
							  startAdornment: <Search/>,
							  endAdornment: <Clear onClick={() => setInputSearchValue('')} fontSize={'small'} sx={{cursor: 'pointer'}}/>}} />
		</Box>
		<Container sx={{p:3}}>
			<Card>
				<CardHeader
					title={'Zu folgenden Nutzern können Sie wechseln'}
					subheader={auth.changedUser && "Aktuell: " + auth.changedUser.profile.name + " (" + auth.changedUser.profile.preferred_username + ")"}/>
				<CardContent>
					<List dense>
						{suggestions.map((user:KeycloakUser,i:number) => {
							return <ListItem alignItems="flex-start" key={i} onClick={selectLogin} value={i} sx={{cursor:'pointer'}}>
										<ListItemButton>
											<ListItemIcon>
												{user === requestedSubject ? <CheckCircle color={'primary'} /> : <CircleOutlined  color={'primary'}/>}
											</ListItemIcon>
											<ListItemText
												primary={user.firstName + " " + user.lastName + " ("+ user.username + ")"}
											/>
										</ListItemButton>
									</ListItem>
						})}
					</List>
				</CardContent>
				<CardActions sx={{p:3}}>
					<Button variant={"contained"} type="button" title="Wechseln" onClick={changeLogin}>Wechseln</Button>
				</CardActions>
			</Card>
		</Container>
	</>
}
