//throw an Error for all non 2xx status codes with response body as text-message
export const defaultFetchErrorHandler = async (response:Response) => {
	if (!response.ok) {
		throw new Error(await response.text());
	}
	return response;
}

export function defaultResponseErrorCheck(errorMsgPrefix:string) {
	return async (response:Response) => {
		if (!response.ok) {
			throw new Error(errorMsgPrefix + response.statusText + ", " + await response.text());
		}
		return response;
	};
}
